/* eslint no-underscore-dangle: 0 */
import React, { useMemo, RefObject } from "react";
import TagManager from "react-gtm-module";
import {
  Box,
  CardMedia,
  Dialog,
  Drawer,
  IconButton,
  Snackbar,
  SvgIcon,
  SwipeableDrawer,
  Tab,
  Typography,
} from "@material-ui/core";

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";
import EmojiObjectsOutlinedIcon from "@material-ui/icons/EmojiObjectsOutlined";

import { Adsense } from "@ctrl/react-adsense";

import { useUserDataStore } from "commons/utils/user-data";

import { Alert, AlertProps } from "components/alert/alert.component";
import SignInComponent from "pages/signin/signin.component";

import { Redirect } from "react-router-dom";

// assets
import OpenSearchLogo from "assets/icons/logo.svg";
import ChatItem from "components/chat-item/chat-item.component";
import ChatDivider from "components/chat-date-divider/chat-date-divider.component";
import InfoTooltip from "components/info-tooltip/info-tooltip.component";
import IconButtonGeneric from "components/icon-button/icon-button.component";

import { CLIENT_NAME } from "graphql/client";
import { OS_TYPE_NAMES } from "graphql/open-search/open-serach.utils";
import {
  OpenSearchQuery,
  OsAnswersFragment,
  useOpenSearchLazyQuery,
} from "graphql/search/qanda-types-and-hooks";
import {
  OpenSearchProQuery,
  OsAnswersProFragment,
  OsMediaProFragment,
  useOpenSearchProLazyQuery,
} from "graphql/search/qanda-pro-types-and-hooks";
import {
  AudionautSubscriptionFragmentFragment,
  AudionautPlanFragmentFragment,
  OrganisationData,
  useGetAudionautPlansQuery,
  useGetCurrentSubscriptionInfoQuery,
  useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery,
  useGetCurrentUserOrganisationQuery,
  BillingAddress,
  PaymentMethod,
  useCreatePlanSubscriptionMutation,
  useCreatePlanSubscriptionWithCouponMutation,
  useGetReferralLinkByCodeLazyQuery,
  useSetReferralLinkMutation,
  useSensReferalRewardEmailLazyQuery,
  useSendReferralInviteLazyQuery,
  ReferredLinkFragment,
} from "graphql/types-and-hooks";
import {
  createEmptyBillingAddress,
  createEmptyPaymentMethod,
} from "graphql/audionaut.utils";
import { OsMediaFragment } from "graphql/open-search/types-and-hooks";
import { isServerError } from "errors/server.errors";
import { UIError, UIErrorCodes, useAppErrorHandler } from "errors/app.errors";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  corporatusQuery,
  getChatHistoric,
  saveChatMessage,
  updateItemHistoric,
} from "graphql/corporatus/corporatus_rest_graphql";
import { getSearchRequestConfig } from "graphql/corporatus/corporatus_rest_graphql.utils";
import {
  ChatHistoricItem,
  SearchResponseStreamModel,
  SRSData,
  SRSDataSource,
} from "graphql/corporatus/corporatus_rest_graphql.types";

import MainChatImage from "assets/images/main-chat-image.png";

import hasProductOwnerRole from "components/app-permission-validator/fetch_current_user_profile";
import SearchButton from "components/search-button/search-button.component";
import ChatProSwitch from "components/chat-pro-switch/chat-pro-switch.component";
import BilingModal from "pages/user-plans/components/billing-modal/billing-modal.component";
import ScrollToTopButton from "components/scrollToTop-button/scrollToTop-button.component";
import ChatTopicsList from "components/chat-topics-list/chat-topic-list.component";
import { TopicItemModel } from "components/chat-topic-item/chat-topic-item.component";
import FiguresListComponet from "components/figures-list/figures-list.component";
import GenericInfoCard, {
  GenericInfoCardInterface,
} from "components/generic-info-card/generic-info-card.component";
import ButtonPrimary from "components/button-primary/button-primary.component";

import { getFormattedDate } from "commons/utils/date-format";
import { isMobileResolution } from "commons/utils/device-info.util";

import { StreamResponseService } from "../../commons/services/handle-stream-response";
import { HandleGlobalEvents } from "../../commons/utils/handle-global-events";

import {
  ChatItemInterface,
  MessageTypes,
  tooltipData,
  TrialInfoData,
  TutoContentInterface,
  tutoMockData,
} from "./public-search.utils";
import TutorialContent from "./public-search-tuto.component";

import { ReactComponent as InsightsIcon } from "../../assets/icons/insights_mobile.svg";

import SC from "./public-search.styles";

type Mutable = {
  -readonly [key in keyof AudionautSubscriptionFragmentFragment]: AudionautSubscriptionFragmentFragment[key];
};

const { REACT_APP_API_REST_CORPORATUS_SERVER_URL_WS } = process.env;
const { REACT_APP_API_REST_CORPORATUS_APIKEY } = process.env;
const { REACT_APP_TRIAL_PLAN_ID } = process.env;

const PublicSearchPage: React.FC = () => {
  const classes = SC.useStyles();
  const refChatContainer = React.useRef<HTMLBodyElement>(null);
  const chatMainContainerRef = React.useRef<HTMLBodyElement>(null);
  const OSDescRef = React.useRef<HTMLBodyElement>(null);
  const insightRef = React.useRef<HTMLBodyElement>(null);

  const [removeStylesTimes1, setRemovedStylesTimes1] = React.useState(0);
  const [removeStylesTimes2, setRemovedStylesTimes2] = React.useState(0);
  const [removeStylesTimes3, setRemovedStylesTimes3] = React.useState(0);
  const [removeStylesTimes4, setRemovedStylesTimes4] = React.useState(0);
  const [intervalId1, setIntervalId1] = React.useState<any>(-1);
  const [intervalId2, setIntervalId2] = React.useState<any>(-1);
  const [intervalId3, setIntervalId3] = React.useState<any>(-1);
  const [intervalId4, setIntervalId4] = React.useState<any>(-1);

  const [streamResponseInstance, setStreamResponseInstance] =
    React.useState<any>(null);
  const [handleGobalEventsInstance, setHandleGobalEventsInstance] =
    React.useState<any>(null);
  const [redirectToManage, setRedirectToManage] =
    React.useState<boolean>(false);

  const [termToSearch, setTermToSearch] = React.useState<string>("");
  const [typingSimulation, setTypingSimulation] = React.useState<string>("");
  const [clearInput, setClearInput] = React.useState<boolean>(false);
  const [disabled, setDisabled] = React.useState<boolean>(false);
  const [toRegister, setToRegister] = React.useState<boolean>(false);
  const [showLogin, setShowLogin] = React.useState<boolean>(false);
  const [results, setResults] = React.useState<OsMediaFragment[]>([]);
  const [enabledChatPro, setEnabledChatPro] = React.useState<boolean>(false);
  const [tabShowed, setTabShowed] = React.useState<string>("1");
  const isProductOwner = hasProductOwnerRole();

  const [streamResponse, setStreamResponse] =
    React.useState<SRSData | null>(null);
  const [newStreamResponse, setNewStreamResponse] =
    React.useState<ChatItemInterface | null>(null);
  const [isStreamResponse, setIsStreamResponse] =
    React.useState<boolean>(false);

  const [history, setHistory] = React.useState<ChatItemInterface[]>([]);
  const [historyBkp, setHistoryBkp] = React.useState<ChatItemInterface[]>([]);

  const [historicShowed, setHistoricShowed] = React.useState<boolean>(false);
  const [currentChatHItem, setCurrentChatHItem] =
    React.useState<ChatHistoricItem | null>(null);
  const [chatHistoric, setChatHistoric] = React.useState<ChatHistoricItem[]>(
    []
  );
  const [discussedParams, setDiscussedParams] =
    React.useState<{
      index: number;
      sessionId: string;
      caption: "";
    } | null>(null);
  const [sourcesImages, setSourcesImages] =
    React.useState<{ secureUrl: string; caption: string }[] | null>(null);
  const [responseRegenerate, setResponseRegenerate] =
    React.useState<
      (ChatHistoricItem & { chatIndex: number; historicIndex: number }) | null
    >(null);

  const bottomEle = React.useRef<any>(null);
  const [expanded, setExpanded] = React.useState(false);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const [snackBarMessage, setSnackBarMessage] =
    React.useState<AlertProps & { delay?: number }>();
  const userData = useUserDataStore((state: any) => state.userData);
  const uuidTemp = useMemo(() => userData.uuid, [userData]);
  const userDataLocal = useMemo(() => userData, [userData]);

  const [showTooltipArrow, setShowTooltipArrow] = React.useState(false);
  const [openTooltip, setOpenTooltip] = React.useState<boolean>(false);
  const [currentContentIndex, setCurrentContentIndex] =
    React.useState<number>(0);
  const [tooltipContent, setTooltipContent] =
    React.useState<TutoContentInterface>(tooltipData[currentContentIndex]);
  const [tooltipShowed, setTooltipShowed] = React.useState<boolean>(() => {
    return streamResponseInstance ? streamResponseInstance.tutoShowed : true;
  });

  const [showToolTip, setShowToolTip] = React.useState<boolean>(true);
  const [trialInfoData, setTrialInfoData] =
    React.useState<GenericInfoCardInterface>(TrialInfoData[0]);
  const [openInsight, setOpenInsight] = React.useState(false);

  const planUpgrade = React.useMemo(() => {
    const doUpgrade =
      !!localStorage.getItem("planUpgrade") &&
      localStorage.getItem("planUpgrade") === "chatPro";
    return doUpgrade;
  }, []);
  const [openBillingModal, setOpenBillingModal] = React.useState(false);
  const [currentAddress, setCurrentAddress] = React.useState(() =>
    createEmptyBillingAddress()
  );
  const [currentPaymentMethod, setCurrentPaymentMethod] =
    React.useState<PaymentMethod>(createEmptyPaymentMethod());

  const inputRef = React.useRef<HTMLElement>(null);
  const chatItemRef = React.useRef<HTMLElement>(null);

  const [tooltipElemRef, setTooltipElemRef] =
    React.useState<RefObject<HTMLElement>>(inputRef);

  const [referralLink, setReferralLink] =
    React.useState<ReferredLinkFragment | null>(null);
  const referralCode = React.useMemo(() => {
    return localStorage.getItem("referralCode");
  }, []);

  const {
    data: currentSubscriptionInfoData,
    loading: currentSubscriptionInfoLoading,
    error: currentSubscriptionInfoError,
  } = useGetCurrentSubscriptionInfoQuery();

  const userHasActiveSubscription = React.useMemo(
    () =>
      currentSubscriptionInfoData &&
      currentSubscriptionInfoData?.getCustomerSubscriptionInfo !== null &&
      currentSubscriptionInfoData?.getCustomerSubscriptionInfo
        ?.hasActiveMeteredSubscription,
    [currentSubscriptionInfoData]
  );

  const {
    data: currentSubscriptionData,
    loading: currentSubscriptionLoading,
    error: currentSubscriptionError,
  } = useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery({
    fetchPolicy: "no-cache",
  });

  const {
    data: currentUserOrganisation,
    loading: getCurrentUserOrganisationLoading,
    error: getCurrentUserOrganisationError,
  } = useGetCurrentUserOrganisationQuery({
    fetchPolicy: "no-cache",
  });

  const {
    data: plans,
    loading: getAudionautPlansLoading,
    error: getAudionautPlansError,
  } = useGetAudionautPlansQuery({
    fetchPolicy: "no-cache",
  });

  const [
    createPlanSubscription,
    {
      data: subscriptionData,
      loading: subscriptionLoading,
      error: subscriptionError,
    },
  ] = useCreatePlanSubscriptionMutation();

  const [
    createPlanSubscriptionWithCoupon,
    {
      data: subsCouponData,
      loading: subsCouponLoading,
      error: subsCouponError,
    },
  ] = useCreatePlanSubscriptionWithCouponMutation();

  // Definition of query and mutation of api rest requests

  const [
    searchQueryRequest,
    {
      data: searchQueryData,
      loading: searchQueryLoading,
      error: searchQueryError,
    },
  ] = useMutation(corporatusQuery);

  const [
    saveChatMessageRequest,
    {
      data: saveChatMessageData,
      loading: saveChatMessageLoading,
      error: saveChatMessageError,
    },
  ] = useMutation(saveChatMessage, {
    fetchPolicy: "no-cache",
    context: {
      clientName: CLIENT_NAME.CORPORATUSAPIREST,
      noAuthNeeded: true,
      headers: {
        Authorization: "Bearer O3IGNECRD5KNJCN38L9JGIVBVU8G6W0L1Y0BOCTS",
        "Content-Type": "application/json",
      },
    },
  });

  const [
    updateChatMessageRequest,
    { loading: updateChatMessageLoading, error: updateChatMessageError },
  ] = useMutation(updateItemHistoric, {
    fetchPolicy: "no-cache",
    context: {
      clientName: CLIENT_NAME.CORPORATUSAPIREST,
      noAuthNeeded: true,
      headers: {
        Authorization: "Bearer O3IGNECRD5KNJCN38L9JGIVBVU8G6W0L1Y0BOCTS",
        "Content-Type": "application/json",
      },
    },
  });

  const [
    getChatHistoricQuery,
    {
      data: getChatHistoricData,
      loading: getChatHistoricLoading,
      error: getChatHistoricError,
    },
  ] = useLazyQuery(getChatHistoric, {
    fetchPolicy: "no-cache",
    context: {
      clientName: CLIENT_NAME.CORPORATUSAPIREST,
      noAuthNeeded: true,
      headers: {
        Authorization: "Bearer O3IGNECRD5KNJCN38L9JGIVBVU8G6W0L1Y0BOCTS",
        "Content-Type": "application/json",
      },
    },
  });

  const [
    getReferralLink,
    {
      data: getReferralLinkData,
      loading: getReferralLinkLoading,
      error: getReferralLinkError,
    },
  ] = useGetReferralLinkByCodeLazyQuery();

  const [setReferralLinkMut, { loading: useReferralLinkLoading }] =
    useSetReferralLinkMutation();
  const [sendReferralRewardEmail] = useSensReferalRewardEmailLazyQuery();

  const errorHandler = useAppErrorHandler(
    currentSubscriptionError ||
      getAudionautPlansError ||
      subscriptionError ||
      subsCouponError
  );

  const chatProPlanData = React.useMemo(() => {
    return plans && plans.getAudionautPlans
      ? plans.getAudionautPlans.find(
          (plan) => plan?.id === REACT_APP_TRIAL_PLAN_ID
        )
      : null;
  }, [plans]);

  const currentSubscription = React.useMemo(
    () =>
      (currentSubscriptionData?.getCurrentUserOrganisationCurrentPeriodSubscription as Mutable) ??
      null,
    [currentSubscriptionData]
  );

  const userOrganization: OrganisationData | null = React.useMemo(
    () => currentUserOrganisation?.getCurrentUserOrganisation ?? null,
    [currentUserOrganisation]
  );

  const createSubscription = React.useCallback(
    async (id?: string, couponCode?: string) => {
      try {
        if (couponCode) {
          createPlanSubscriptionWithCoupon({
            variables: {
              planId: id ?? "",
              coupon: couponCode,
            },
          });
        } else {
          await createPlanSubscription({
            variables: {
              planId: id ?? "",
            },
          });
        }

        // After user is subscribed successfully, we do the referral stuffs to update info referral link
        if (referralLink && referralLink.id) {
          const { referredBy } = referralLink;
          setReferralLinkMut({
            variables: {
              userId: userDataLocal.id,
              referralLinkId: referralLink.id ?? "",
            },
          });

          sendReferralRewardEmail({
            variables: {
              email: referredBy?.email ?? "",
              name: referredBy?.firstName ?? "",
            },
          });

          localStorage.removeItem("referralCode");
        }

        TagManager.dataLayer({
          dataLayer: {
            event: "planSubscribed",
          },
        });

        setSnackBarMessage({
          message:
            "The plan subscription has been updated successfully, Redirection to home...",
          severity: "success",
        });
        setTimeout(() => {
          // setRedirect(true);
          setEnabledChatPro(true);
          window.location.reload();
        }, 3000);
      } catch (error: any) {
        TagManager.dataLayer({
          dataLayer: {
            event: "PlanSubscriptionFailed",
          },
        });

        if (isServerError(error)) {
          errorHandler(error);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the plan subscription"
            )
          );
        }
      }
    },
    [
      createPlanSubscription,
      createPlanSubscriptionWithCoupon,
      errorHandler,
      setEnabledChatPro,
      referralLink,
      setReferralLinkMut,
      sendReferralRewardEmail,
      userDataLocal,
    ]
  );

  const handleBillingModal = React.useCallback(() => {
    setOpenBillingModal(!openBillingModal);
  }, [setOpenBillingModal, openBillingModal]);

  const [startSearch, { loading, error: openSearchError, data: searchResult }] =
    useOpenSearchLazyQuery({
      context: {
        clientName: CLIENT_NAME.AUDIONAUTSQANDA,
      },
      fetchPolicy: "no-cache",
    });

  const [
    startSearchPro,
    { loading: loadingPro, error: openSearchProError, data: searchProResult },
  ] = useOpenSearchProLazyQuery({
    context: {
      clientName: CLIENT_NAME.AUDIONAUTSQANDAPRO,
    },
    fetchPolicy: "no-cache",
  });

  const resetInputField = React.useCallback(() => {
    setClearInput(true);
    setTimeout(() => {
      setClearInput(false);
    }, 300);
  }, []);

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const startTuto = React.useCallback(() => {
    setCurrentContentIndex(1);
    setTooltipElemRef(inputRef);
    setTooltipContent(tooltipData[1]);
    setOpenTooltip(true);
    setShowTooltipArrow(true);
    // setTypingSimulation(tutoMockData.question);
  }, [setCurrentContentIndex, setTooltipContent]);

  const successTooltipHandler = React.useCallback(() => {
    if (currentContentIndex === 0) {
      startTuto();
    } else if (currentContentIndex > 0 && currentContentIndex < 4) {
      setTooltipElemRef(inputRef);
      setShowTooltipArrow(true);
      setCurrentContentIndex(currentContentIndex + 1);
      setTooltipContent(tooltipData[currentContentIndex + 1]);
    } else if (currentContentIndex === 4) {
      setCurrentContentIndex(0);
      setTooltipElemRef(inputRef);
      setTooltipContent(tooltipData[0]);
      setOpenTooltip(false);
      setShowTooltipArrow(false);
    }
  }, [
    currentContentIndex,
    setCurrentContentIndex,
    setTooltipElemRef,
    startTuto,
  ]);

  const cancelTooltipHandler = React.useCallback(() => {
    if (currentContentIndex === 2) {
      setHistory(historyBkp);
      setHistoryBkp([]);
    }
    setTypingSimulation("");
    setCurrentContentIndex(0);
    setTooltipElemRef(inputRef);
    setTooltipContent(tooltipData[0]);
    setOpenTooltip(false);
    setShowTooltipArrow(false);
    resetInputField();
  }, [
    currentContentIndex,
    setCurrentContentIndex,
    setShowTooltipArrow,
    setHistoryBkp,
    historyBkp,
    resetInputField,
  ]);

  const changeSwitchHandler = React.useCallback(
    (chatPro: boolean) => {
      if (!enabledChatPro && !isProductOwner && !userHasActiveSubscription) {
        handleBillingModal();
      } else {
        setEnabledChatPro(!enabledChatPro);
      }
    },
    [
      handleBillingModal,
      isProductOwner,
      userHasActiveSubscription,
      setEnabledChatPro,
      enabledChatPro,
    ]
  );

  const handleTabChange = React.useCallback(
    (event: React.ChangeEvent<{}>, newValue: string) => {
      // console.debug("====> Change tab: ", newValue);
      setTabShowed(newValue);
    },
    []
  );

  // Handler to build a topic messages historic and display it
  const openMessagesByTopic = React.useCallback(
    (item: ChatHistoricItem | null) => {
      if (item) {
        const messagesToShow: ChatItemInterface[] = chatHistoric
          .filter((message) => message.session_id === item.session_id)
          .flatMap((message) => {
            // console.debug("=====> MESSAGE: ", message);
            const messagesDates = message.created_at.split("-");
            const userMessage: ChatItemInterface = {
              message: message.query,
              created: messagesDates[0],
              type: MessageTypes.LOCAL,
            };
            const remoteMessage: ChatItemInterface = {
              message: message.response,
              created: messagesDates[1],
              type: MessageTypes.REMOTE,
              detail: message.resource,
              sessionId: message.session_id,
            };

            return [userMessage, remoteMessage];
          });

        if (!historicShowed) {
          setHistoryBkp(history);
        }

        setHistoricShowed(true);
        setHistory(messagesToShow);
        // console.debug("=====> Show messages: ", messagesToShow);
      } else {
        setHistory(historyBkp);
        setHistoryBkp([]);
        setHistoricShowed(false);
      }

      streamResponseInstance.clearSourcesImages();
      handleGobalEventsInstance.checkingInjectedStyles();
      setDiscussedParams(null);
    },
    [
      chatHistoric,
      history,
      historyBkp,
      historicShowed,
      streamResponseInstance,
      handleGobalEventsInstance,
    ]
  );

  // Handler to start regenerate a response query passing some caption
  const onDiscussHandler = React.useCallback(
    (item: any) => {
      // console.debug("=====> chatHistoric: ", chatHistoric);
      const historicMessage = chatHistoric.filter(
        (historicItem) => historicItem.session_id === discussedParams?.sessionId
      )[
        discussedParams && discussedParams.index
          ? (discussedParams?.index + 1) / 2 - 1
          : 0
      ];

      setDiscussedParams((oldParams) => {
        const currentParams = oldParams as {
          index: number;
          sessionId: string;
          caption: "";
        };
        currentParams.caption = item.caption;
        return currentParams;
      });

      setTermToSearch(historicMessage.query);
    },
    [discussedParams, chatHistoric]
  );

  // Handler to regenerate some query response
  const onRegenerateResponseHandler = React.useCallback(
    (index: number) => {
      // console.debug("=====> Regenerate query: ", index);
      const currentResponse = history[index];
      // console.debug("=====> currentResponse: ", currentResponse);
      const historicIndex = index ? (index + 1) / 2 - 1 : 0;
      const historicMessage = {
        ...chatHistoric.filter(
          (historicItem) =>
            historicItem.session_id === currentResponse.sessionId
        )[historicIndex],
      };

      const responseToRegenerate = {
        ...historicMessage,
        chatIndex: index,
        historicIndex,
      };
      // console.debug("=====> hitoric item: ", responseToRegenerate);
      setResponseRegenerate(responseToRegenerate);

      setTermToSearch(historicMessage.query);
    },
    [history, chatHistoric]
  );

  const clearStylesInjected1 = React.useCallback(
    (currentRef, currentIntervalId) => {
      // console.debug("=====> OSDescRef clearStylesInjected1!!!");
      // console.debug("=====> OSDescRef interval id: ", currentIntervalId);
      if (
        currentRef &&
        currentRef.current &&
        currentRef.current.attributes &&
        currentRef.current.attributes.getNamedItem("style") &&
        currentRef.current.attributes.getNamedItem("style")?.value
      ) {
        currentRef.current.attributes.removeNamedItem("style");
        clearInterval(currentIntervalId);
        setIntervalId1(-1);
        // console.debug(
        //   "=====> OSDescRef cleaning the interval id 1",
        //   currentIntervalId
        // );
      }
      setRemovedStylesTimes1((oldTime) => {
        const newTime = oldTime + 1;
        if (newTime > 4) {
          clearInterval(currentIntervalId);
          setIntervalId1(-1);
          // console.debug(
          //   "=====> OSDescRef cleaning the interval id 2",
          //   currentIntervalId
          // );
        }
        return newTime;
      });
    },
    []
  );

  const clearStylesInjected2 = React.useCallback(
    (currentRef, currentIntervalId) => {
      // console.debug("=====> refChatContainer clearStylesInjected2!!!");
      // console.debug("=====> refChatContainer interval id: ", currentIntervalId);
      if (
        currentRef &&
        currentRef.current &&
        currentRef.current.attributes &&
        currentRef.current.attributes.getNamedItem("style") &&
        currentRef.current.attributes.getNamedItem("style")?.value
      ) {
        currentRef.current.attributes.removeNamedItem("style");
        clearInterval(currentIntervalId);
        setIntervalId2(-1);
        // console.debug(
        //   "=====> refChatContainer cleaning the interval id 1",
        //   currentIntervalId
        // );
      }
      setRemovedStylesTimes2((oldTime) => {
        const newTime = oldTime + 1;
        if (newTime > 4) {
          clearInterval(currentIntervalId);
          setIntervalId2(-1);
          // console.debug(
          //   "=====> refChatContainer cleaning the interval id 2",
          //   currentIntervalId
          // );
        }
        return newTime;
      });
    },
    []
  );

  const clearStylesInjected3 = React.useCallback(
    (currentRef, currentIntervalId) => {
      // console.debug("=====> chatMainContainerRef clearStylesInjected3!!!");
      // console.debug(
      //   "=====> chatMainContainerRef interval id: ",
      //   currentIntervalId
      // );
      if (
        currentRef &&
        currentRef.current &&
        currentRef.current.attributes &&
        currentRef.current.attributes.getNamedItem("style") &&
        currentRef.current.attributes.getNamedItem("style")?.value
      ) {
        currentRef.current.attributes.removeNamedItem("style");
        clearInterval(currentIntervalId);
        setIntervalId3(-1);
        // console.debug(
        //   "=====> chatMainContainerRef cleaning the interval id 1",
        //   currentIntervalId
        // );
      }
      setRemovedStylesTimes3((oldTime) => {
        const newTime = oldTime + 1;
        if (newTime > 4) {
          clearInterval(currentIntervalId);
          setIntervalId3(-1);
          // console.debug(
          //   "=====> chatMainContainerRef cleaning the interval id 2",
          //   currentIntervalId
          // );
        }
        return newTime;
      });
    },
    []
  );

  const clearStylesInjected4 = React.useCallback(
    (currentRef, currentIntervalId) => {
      if (
        currentRef &&
        currentRef.current &&
        currentRef.current.attributes &&
        currentRef.current.attributes.getNamedItem("style") &&
        currentRef.current.attributes.getNamedItem("style")?.value
      ) {
        currentRef.current.attributes.removeNamedItem("style");
        clearInterval(currentIntervalId);
        setIntervalId4(-1);
      }
      setRemovedStylesTimes4((oldTime) => {
        const newTime = oldTime + 1;
        if (newTime > 4) {
          clearInterval(currentIntervalId);
          setIntervalId4(-1);
        }
        return newTime;
      });
    },
    []
  );

  // const onHandlerSourceClicked = React.useCallback(
  //   (item: OsMediaFragment | OsMediaProFragment) => {
  //     setSourceSelected(item);
  //   },
  //   []
  // );

  React.useEffect(() => {
    if (!tooltipShowed && streamResponseInstance) {
      setOpenTooltip(true);
      setShowTooltipArrow(true);
      setTooltipShowed(true);
      streamResponseInstance.tutoShowed = true;
    }
  }, [tooltipShowed, setTooltipShowed, streamResponseInstance]);

  React.useEffect(() => {
    setEnabledChatPro((isProductOwner || userHasActiveSubscription) ?? false);
  }, [isProductOwner, userHasActiveSubscription, setEnabledChatPro]);

  React.useEffect(() => {
    try {
      if (termToSearch && termToSearch.length) {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);

        const queriesPerformed = chatHistoric.filter((item) => {
          const itemDate = new Date(Number(item.created_at.split("-")[0]));
          itemDate.setHours(0, 0, 0, 0);
          // console.debug("=====> itemDate: ", itemDate);
          return currentDate.getTime() === itemDate.getTime();
        });

        // console.debug(
        //   "=====> queriesPerformed: ",
        //   queriesPerformed,
        //   queriesPerformed.length
        // );
        // console.debug("=====> history lenght: ", chatHistoric.length);

        if (queriesPerformed.length > 19 && !userHasActiveSubscription) {
          setShowToolTip(false);
          setOpenTooltip(true);
          setTermToSearch("");
          setDisabled(false);
          resetInputField();
        } else {
          // console.debug("=====> start search: ", termToSearch);
          let currentRegenerateIndex: null | number = null;
          let historicMessage: null | ChatHistoricItem = null;
          let historicContext: { query: string; response: string }[] | null =
            null;
          let latestSessionId: string | null = null;

          if (historicShowed && history.length > 1 && !discussedParams) {
            const historicLength = history.length;
            historicContext = [
              {
                query: history[historicLength - 2].message,
                response: history[historicLength - 1].message,
              },
            ];
            latestSessionId = history[historicLength - 1].sessionId ?? null;
          }
          // console.debug(
          //   "=====> History context: ",
          //   historicContext,
          //   history,
          //   latestSessionId
          // );

          let disCaption: string | null = null;
          let disResponse: string | null = null;
          if (
            discussedParams &&
            discussedParams.caption &&
            !responseRegenerate
          ) {
            historicMessage = chatHistoric.filter(
              (historicItem) =>
                historicItem.session_id === discussedParams?.sessionId
            )[
              discussedParams && discussedParams.index
                ? (discussedParams?.index + 1) / 2 - 1
                : 0
            ];
            disCaption = discussedParams.caption;
            disResponse = historicMessage.response;
            currentRegenerateIndex = discussedParams.index;

            historicMessage.query = `Response disscussed with ${disCaption}`;

            // console.debug("====> discussedParams: ", historicMessage);
          }

          if (responseRegenerate) {
            currentRegenerateIndex = responseRegenerate.chatIndex;
            historicMessage = chatHistoric.filter(
              (historicItem) =>
                historicItem.session_id === responseRegenerate.session_id
            )[
              currentRegenerateIndex ? (currentRegenerateIndex + 1) / 2 - 1 : 0
            ];
          }

          // serach response stream implementation
          streamResponseInstance.streamResponse(
            termToSearch,
            enabledChatPro ? "pro" : "free",
            REACT_APP_API_REST_CORPORATUS_SERVER_URL_WS,
            REACT_APP_API_REST_CORPORATUS_APIKEY,
            "ws",
            historicContext,
            disResponse,
            disCaption,
            currentRegenerateIndex
          );
          // END
          const queryDate = new Date();
          const responseDate = new Date();

          setHistory((oldHistory: ChatItemInterface[]) => {
            let newHistory = oldHistory;
            if (responseRegenerate === null) {
              newHistory = [
                ...oldHistory,
                {
                  message:
                    currentRegenerateIndex &&
                    discussedParams &&
                    discussedParams.caption
                      ? `Response disscussed with ${discussedParams.caption}`
                      : termToSearch,
                  created: queryDate.toDateString(),
                  type: MessageTypes.LOCAL,
                },
                {
                  message: "",
                  created: responseDate.toDateString(),
                  type: MessageTypes.REMOTE,
                  detail: [],
                  sessionId:
                    historicShowed && latestSessionId
                      ? latestSessionId
                      : uuidTemp,
                  isLastEvent: false,
                },
              ];
            } else {
              newHistory[currentRegenerateIndex ?? 0] = {
                message: "",
                created: newHistory[currentRegenerateIndex ?? 0].created,
                type: MessageTypes.REMOTE,
                detail: newHistory[currentRegenerateIndex ?? 0].detail,
                sessionId: newHistory[currentRegenerateIndex ?? 0].sessionId,
                isRegenerate: true,
                isLastEvent: false,
              };
            }

            // console.debug("=====> new history:", newHistory);

            streamResponseInstance.setCurrentHistory(newHistory);
            handleGobalEventsInstance.checkingInjectedStyles();
            return newHistory;
          });

          const chatHMessage: ChatHistoricItem =
            historicMessage === null
              ? {
                  session_id:
                    historicShowed && latestSessionId
                      ? latestSessionId
                      : uuidTemp,
                  query: termToSearch,
                  created_at: `${queryDate.getTime()}-${responseDate.getTime()}`,
                  email: userDataLocal.email,
                  response: "",
                  resource: [],
                }
              : historicMessage;

          // console.debug("=====> set current historic chat item: ", chatHMessage);
          setCurrentChatHItem(chatHMessage);

          setTermToSearch("");
          setDisabled(true);
          resetInputField();

          TagManager.dataLayer({
            dataLayer: {
              userQuestion: termToSearch,
            },
          });
          TagManager.dataLayer({
            dataLayer: {
              event: "userQuestion",
            },
          });
        }
      }
    } catch (error) {
      console.error("=====> Error while try search a term: ", error);
      setSnackBarMessage({
        message: "Error while try perform a request to the chatbot!",
        severity: "error",
      });
    }
  }, [
    termToSearch,
    searchQueryRequest,
    resetInputField,
    setStreamResponse,
    streamResponseInstance,
    userDataLocal,
    uuidTemp,
    historicShowed,
    history,
    discussedParams,
    chatHistoric,
    userHasActiveSubscription,
    handleBillingModal,
    responseRegenerate,
    enabledChatPro,
    handleGobalEventsInstance,
  ]);

  React.useEffect(() => {
    if (newStreamResponse) {
      let currentRegenerateIndex = discussedParams
        ? discussedParams.index
        : null;

      if (responseRegenerate) {
        currentRegenerateIndex = responseRegenerate.chatIndex;
      }
      // console.debug("=====> discussed params index: ", currentRegenerateIndex);
      setHistory((oldHistory: ChatItemInterface[]) => {
        const historyUpdated = [...oldHistory];
        if (responseRegenerate === null) {
          historyUpdated[historyUpdated.length - 1] = {
            ...historyUpdated[historyUpdated.length - 1],
            message: newStreamResponse.message,
            detail: currentRegenerateIndex ? [] : newStreamResponse.detail,
          };
        } else {
          historyUpdated[currentRegenerateIndex ?? 0] = {
            ...historyUpdated[currentRegenerateIndex ?? 0],
            message: newStreamResponse.message,
            detail: newStreamResponse.detail,
          };
        }
        return historyUpdated;
      });
    }
  }, [
    newStreamResponse,
    setStreamResponse,
    searchQueryData,
    streamResponse,
    currentChatHItem,
    discussedParams,
    disabled,
    responseRegenerate,
  ]);

  // hook to do the historic update
  React.useEffect(() => {
    // console.debug("=====> status of response message ws: ", disabled);
    if (!disabled) {
      if (currentChatHItem) {
        let currentRegenerateIndex = discussedParams
          ? discussedParams.index
          : null;

        if (responseRegenerate) {
          currentRegenerateIndex = responseRegenerate.chatIndex;
        }

        setChatHistoric((oldHistoric) => {
          let currentHistoric = [...oldHistoric];

          if (!responseRegenerate && currentRegenerateIndex != null) {
            currentChatHItem.resource = [];
          }

          if (responseRegenerate == null) {
            // console.debug(
            //   "=====> Adding new historic item: ",
            //   currentChatHItem
            // );
            currentHistoric = [currentChatHItem, ...currentHistoric];
          } else {
            // console.debug("=====> currentHistoric: ", currentHistoric);
            const currentHistoricIndex = currentHistoric.findIndex(
              (message) => message.rid === responseRegenerate.rid
            );
            const currentResources =
              currentHistoric[currentHistoricIndex].resource;
            const currentChatHItemCopy = { ...currentChatHItem };
            currentChatHItemCopy.resource = currentResources;

            // console.debug(
            //   "=====> updating historic item: ",
            //   currentChatHItemCopy,
            //   currentHistoric
            // );
            currentHistoric[currentHistoricIndex] = currentChatHItemCopy;
          }

          return currentHistoric.sort(
            (a, b) =>
              (new Date(b.created_at.split("-")[0]) as any) -
              (new Date(a.created_at.split("-")[0]) as any)
          );
        });
        if (responseRegenerate) {
          // console.debug("=====> save new historic item: ", currentChatHItem);

          updateChatMessageRequest({
            variables: { message: currentChatHItem },
          });
          setDiscussedParams(null);
        } else {
          saveChatMessageRequest({ variables: { message: currentChatHItem } });
        }

        setCurrentChatHItem(null);
      }
    }
  }, [
    disabled,
    currentChatHItem,
    discussedParams,
    saveChatMessageRequest,
    updateChatMessageRequest,
    responseRegenerate,
  ]);

  React.useEffect(() => {
    if (
      saveChatMessageData &&
      saveChatMessageData.chatMessage &&
      saveChatMessageData.chatMessage.rid
    ) {
      const {
        chatMessage: { rid },
      } = saveChatMessageData;
      // console.debug("=======> saveChatMessageData: ", saveChatMessageData);
      setChatHistoric((oldChatHistoric) => {
        const currentChatHistoric = [...oldChatHistoric];
        const chatIndex = currentChatHistoric.findIndex((item) => !item.rid);
        if (chatIndex > -1) {
          // console.debug(
          //   "=====> chat historic without rid: ",
          //   chatIndex,
          //   currentChatHistoric[chatIndex]
          // );
          currentChatHistoric[chatIndex].rid = rid;
          // console.debug(
          //   "=====> chat historic with rid: ",
          //   chatIndex,
          //   currentChatHistoric[chatIndex].rid
          // );
        }
        return currentChatHistoric;
      });
    }
  }, [saveChatMessageData]);

  // React.useEffect(() => {
  //   if (bottomEle && bottomEle.current) {
  //     bottomEle.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // }, [history]);

  React.useEffect(() => {
    if (searchQueryError) {
      console.error("=====> Use Effect error: ", searchQueryError);
      const { message } = searchQueryError;

      setHistory((oldHistory: ChatItemInterface[]) => {
        const historyUpdated = [...oldHistory];
        historyUpdated[historyUpdated.length - 1] = {
          message:
            "Your search didn’t turn up any results, let’s try a different approach.",
          created: new Date().toDateString(),
          type: MessageTypes.REMOTE,
          detail: [],
        };

        // TagManager.dataLayer({
        //   dataLayer: {
        //     questionFailReason: `service-error: ${message}`,
        //   },
        // });
        // TagManager.dataLayer({
        //   dataLayer: {
        //     event: "userQuestionFailed",
        //   },
        // });

        return historyUpdated;
      });

      setSnackBarMessage({
        message,
        severity: "warning",
      });
    }
    // console.debug("=====> set disabled 1.");
    setDisabled(false);
  }, [searchQueryError, setHistory]);

  React.useEffect(() => {
    if (userOrganization && userOrganization.id) {
      const { billingAddresses, paymentMethods } = userOrganization;
      if (billingAddresses && billingAddresses.length > 0) {
        const defaultBillingAddress: BillingAddress =
          (billingAddresses.find(
            (address) => !!address && address.isDefault
          ) as BillingAddress) ?? billingAddresses[0];
        setCurrentAddress(defaultBillingAddress);
      }

      if (paymentMethods && paymentMethods.length > 0) {
        const defaultPaymentMethod: PaymentMethod =
          (paymentMethods.find(
            (payment) => payment?.isDefault
          ) as PaymentMethod) ?? paymentMethods[0];
        setCurrentPaymentMethod(defaultPaymentMethod);
      }
    }
  }, [userOrganization]);

  React.useEffect(() => {
    if (planUpgrade && !userHasActiveSubscription && !!chatProPlanData) {
      setOpenBillingModal(true);
      localStorage.removeItem("planUpgrade");
    }
  }, [planUpgrade, userHasActiveSubscription, chatProPlanData]);

  React.useEffect(() => {
    if (userDataLocal && userDataLocal.email) {
      const emailEncoded = encodeURIComponent(userDataLocal.email);

      getChatHistoricQuery({
        variables: {
          userEmail: emailEncoded,
        },
      });
    }
  }, [userDataLocal, getChatHistoricQuery]);

  React.useEffect(() => {
    if (getChatHistoricData && getChatHistoricData.chatHistoric) {
      // console.debug("======> getChatHistoricData: ", getChatHistoricData);
      const expReg = /^[0-9]+$/;
      setChatHistoric(
        getChatHistoricData.chatHistoric.sort(
          (a, b) =>
            (new Date(
              !expReg.test(b.created_at.split("-")[0])
                ? b.created_at.split("-")[0]
                : Number(b.created_at.split("-")[0])
            ) as any) -
            (new Date(
              !expReg.test(a.created_at.split("-")[0])
                ? a.created_at.split("-")[0]
                : Number(a.created_at.split("-")[0])
            ) as any)
        )
      );
    }
  }, [getChatHistoricData]);

  React.useEffect(() => {
    const currentStreamResponseService = StreamResponseService.getInstance();
    const currentHandleGlobalEvents = HandleGlobalEvents.getInstance();

    setHandleGobalEventsInstance(currentHandleGlobalEvents);

    setIsStreamResponse(false);
    setStreamResponseInstance(currentStreamResponseService);
    setTooltipShowed(currentStreamResponseService.tutoShowed);

    if (currentStreamResponseService.currentHistory.length > 0) {
      setHistory(currentStreamResponseService.currentHistory);
    }

    const subs = currentStreamResponseService.response$.subscribe(
      (response) => {
        // console.debug("=====> Stream Response: ", response);
        if (response && response.isLastEvent) {
          setCurrentChatHItem((message) => {
            const currentMessage = message as ChatHistoricItem;
            // console.debug("=====> currentMessage rid: ", currentMessage?.rid);
            // console.debug("=====> current details: ", currentMessage?.resource);

            currentMessage.response = response.message;
            if (
              !message?.rid ||
              message.rid === null ||
              message.rid === undefined
            ) {
              // console.debug("=====> Assign details data: ", response.detail);
              currentMessage.resource = response.detail;
            }

            // console.debug(
            //   "=====> set current historic chat item 2: ",
            //   currentMessage
            // );

            return currentMessage;
          });
          setDisabled(false);
        }

        setNewStreamResponse(response);

        if (!response) {
          setIsStreamResponse(false);
        } else {
          setIsStreamResponse(true);
        }
      }
    );

    const imagesDataSubs =
      currentStreamResponseService.imagesPersisted$.subscribe((response) => {
        // console.debug("=====> Showing sources images: ", response);
        setSourcesImages([]);
        setSourcesImages(response);
      });

    currentHandleGlobalEvents.checkingInjectedStyles();

    return () => {
      // console.log("This is unmounted.");
      if (subs) subs.unsubscribe();
      if (imagesDataSubs) imagesDataSubs.unsubscribe();
    };
  }, []);

  // // Validate the trial dayl left
  // React.useEffect(() => {
  //   if (
  //     currentSubscription &&
  //     currentSubscription.startsAt &&
  //     currentSubscription.endsAt
  //   ) {
  //     console.debug("=====> currentSubscription: ", currentSubscription);
  //     const { startsAt, endsAt } = currentSubscription;
  //     let diff =
  //       (new Date(endsAt.replaceAll("\\", "")).getTime() -
  //         new Date(startsAt.replaceAll("\\", "")).getTime()) /
  //       1000;
  //     diff /= 60 * 60 * 24;
  //     const daysDiff = Math.abs(Math.round(diff));
  //     console.debug("====> Days diff: ", daysDiff);

  //     diff =
  //       (new Date(endsAt.replaceAll("\\", "")).getTime() -
  //         new Date().getTime()) /
  //       1000;
  //     diff /= 60 * 60 * 24;
  //     const currentDayDiff = Math.abs(Math.round(diff));
  //     console.debug("====> currentDayDiff: ", currentDayDiff);

  //     if (daysDiff === 3 && currentDayDiff === 1) {
  //       console.debug(
  //         "=====> Show the modal to notify to the user the trial days finish soon"
  //       );
  //       setTrialInfoData(TrialInfoData[1]);
  //       setShowToolTip(false);
  //       setOpenTooltip(true);
  //     }
  //   }
  // }, [currentSubscription]);

  React.useEffect(() => {
    if (
      (subscriptionData &&
        subscriptionData?.createSubscription &&
        subscriptionData?.createSubscription.id) ||
      (subsCouponData &&
        subsCouponData?.createSubscription &&
        subsCouponData?.createSubscription.id)
    ) {
      // We send the purchase gtm event
      TagManager.dataLayer({
        dataLayer: {
          transaction_id: subscriptionData
            ? subscriptionData?.createSubscription.id
            : subsCouponData?.createSubscription.id,
        },
      });

      TagManager.dataLayer({
        dataLayer: {
          event: "purchase",
        },
      });

      setTimeout(() => {
        // setRedirect(true);
        window.location.href = "/";
      }, 3000);
    }
  }, [subscriptionData, subsCouponData]);

  React.useEffect(() => {
    if (referralCode) {
      getReferralLink({
        variables: {
          code: referralCode,
        },
      });
    }
  }, [referralCode, getReferralLink]);

  React.useEffect(() => {
    if (getReferralLinkData && getReferralLinkData.GetReferredLinkByCode) {
      setReferralLink(getReferralLinkData.GetReferredLinkByCode);
    }
  }, [getReferralLinkData]);

  React.useEffect(() => {
    // console.debug("=====> chatMainContainerRef: ", chatMainContainerRef);
    if (chatMainContainerRef && chatMainContainerRef.current) {
      const newIntervalId = setInterval(() => {
        clearStylesInjected3(chatMainContainerRef, newIntervalId);
      }, 4000);
      // console.debug("====> newIntervalId", newIntervalId);
      setIntervalId3(newIntervalId);

      const currentHandleGlobalEvents = HandleGlobalEvents.getInstance();
      currentHandleGlobalEvents.checkinginlineStyles$.subscribe((check) => {
        // console.debug("=====> Re checking the injected styles: ", check);
        if (check) {
          if (intervalId3 === -1) {
            const newIntervalId2 = setInterval(() => {
              clearStylesInjected3(chatMainContainerRef, newIntervalId2);
            }, 4000);
            setIntervalId3(newIntervalId2);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatMainContainerRef]);

  React.useEffect(() => {
    if (refChatContainer && refChatContainer.current) {
      const newIntervalId = setInterval(() => {
        clearStylesInjected2(refChatContainer, newIntervalId);
      }, 4000);
      setIntervalId2(newIntervalId);
      // console.debug("====> newIntervalId", newIntervalId);

      const currentHandleGlobalEvents = HandleGlobalEvents.getInstance();
      currentHandleGlobalEvents.checkinginlineStyles$.subscribe((check) => {
        // console.debug("=====> Re checking the injected styles: ", check);
        if (check) {
          if (intervalId2 === -1) {
            const newIntervalId2 = setInterval(() => {
              clearStylesInjected2(refChatContainer, newIntervalId2);
            }, 4000);
            setIntervalId2(newIntervalId2);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refChatContainer]);

  React.useEffect(() => {
    // console.debug("=====> This is mounted OSDescRef!!!");
    if (OSDescRef && OSDescRef.current) {
      const newIntervalId = setInterval(() => {
        clearStylesInjected1(OSDescRef, newIntervalId);
      }, 4000);
      // console.debug("====> newIntervalId", newIntervalId);
      setIntervalId1(newIntervalId);

      const currentHandleGlobalEvents = HandleGlobalEvents.getInstance();
      currentHandleGlobalEvents.checkinginlineStyles$.subscribe((check) => {
        // console.debug("=====> Re checking the injected styles: ", check);
        if (check) {
          if (intervalId1 === -1) {
            const newIntervalId2 = setInterval(() => {
              clearStylesInjected1(OSDescRef, newIntervalId2);
            }, 4000);
            setIntervalId1(newIntervalId2);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [OSDescRef]);

  React.useEffect(() => {
    // console.debug("=====> This is mounted OSDescRef!!!");
    if (insightRef && insightRef.current) {
      const newIntervalId = setInterval(() => {
        clearStylesInjected4(insightRef, newIntervalId);
      }, 4000);
      // console.debug("====> newIntervalId", newIntervalId);
      setIntervalId4(newIntervalId);

      const currentHandleGlobalEvents = HandleGlobalEvents.getInstance();
      currentHandleGlobalEvents.checkinginlineStyles$.subscribe((check) => {
        // console.debug("=====> Re checking the injected styles: ", check);
        if (check) {
          if (intervalId4 === -1) {
            const newIntervalId2 = setInterval(() => {
              clearStylesInjected4(insightRef, newIntervalId2);
            }, 4000);
            setIntervalId4(newIntervalId2);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightRef]);

  return (
    <SC.MainContainer
      ref={chatMainContainerRef}
      className="chat-main-container"
    >
      <SC.OpenSearchContainer height="100vh" width="100%">
        {/* <BannerNavigation
          hideSearchBar={false}
          isLogged={false}
          openRegister={setToRegister}
          openLogin={setShowLogin}
          collapsable
        /> */}
        <SC.Container ref={refChatContainer}>
          {/* <ChatProSwitch
          isPro={enabledChatPro ?? false}
          onChangeSwitch={changeSwitchHandler}
          refContainer={refChatContainer}
          customClasses={history.length === 0 ? classes.noResults : ""}
          showLockIcon={
            (!isProductOwner && !userHasActiveSubscription) ?? false
          }
        /> */}
          {isMobileResolution() ? (
            <SC.InsightMobileButton
              name=""
              type="button"
              icon={<SvgIcon component={InsightsIcon} />}
              onClick={() => {
                setOpenInsight(true);
                handleGobalEventsInstance.checkingInjectedStyles();
              }}
            />
          ) : (
            <SC.OpenInsightsButton
              startIcon={<EmojiObjectsOutlinedIcon />}
              onClick={() => {
                setOpenInsight(true);
                handleGobalEventsInstance.checkingInjectedStyles();
              }}
            >
              Insight
            </SC.OpenInsightsButton>
          )}

          <SC.ResultContainer hidden={history.length === 0}>
            <SC.NoResults
              show={history.length === 0}
              title="No Results Found "
              description="Check your search spelling or use more generic search terms"
            />
            <SC.ResultsContent
              id="audionautChatContanier"
              hidden={history.length === 0}
            >
              <SC.ScrollTopRefElem id="back-to-top-anchor-chat" />
              {/* {results.map((searchItem: OsMediaFragment, index: number) => (
                <GenerateItemSearch id={index} item={searchItem} />
              ))} */}
              {history.map((item: ChatItemInterface, index: number) => {
                const dateString = getFormattedDate(item.created, "MMMM DD");
                let showDateDivider = false;
                if (index === 0) {
                  showDateDivider = true;
                } else if (
                  new Date(item.created).getDate() >
                  new Date(history[index - 1].created).getDate()
                ) {
                  showDateDivider = true;
                }

                let currentIndex = history.length - 1;

                if (responseRegenerate) {
                  currentIndex = responseRegenerate.chatIndex;
                } else if (discussedParams && !responseRegenerate) {
                  currentIndex = discussedParams.index;
                }

                return (
                  <>
                    {showDateDivider ? (
                      <ChatDivider element={dateString} />
                    ) : null}
                    <ChatItem
                      item={item}
                      isPro={enabledChatPro}
                      elemRef={chatItemRef}
                      customClass={
                        currentContentIndex === 2 ? classes.tutoActived : ""
                      }
                      isStream={isStreamResponse && index === currentIndex}
                      itemIndex={index}
                      onShowResources={(
                        sessionId,
                        sourceIndex,
                        showSources
                      ) => {
                        // console.debug(
                        //   "=====> onShowResources: ",
                        //   sessionId,
                        //   sourceIndex
                        // );
                        if (showSources) {
                          streamResponseInstance.assingSourcesImages(
                            sessionId,
                            sourceIndex
                          );
                          setDiscussedParams({
                            index: sourceIndex,
                            sessionId,
                            caption: "",
                          });
                        } else {
                          streamResponseInstance.clearSourcesImages();
                          setDiscussedParams(null);
                        }
                      }}
                      onRegenerateResponse={onRegenerateResponseHandler}
                      isLastItem={index === history.length - 1}
                    />
                  </>
                );
              })}
              <Box ref={bottomEle} />
            </SC.ResultsContent>
            <SC.ScrollToTopElem position="absolute">
              <ScrollToTopButton
                containerId="audionautChatContanier"
                querySelector="back-to-top-anchor-chat"
                refContainer={refChatContainer.current}
              />
            </SC.ScrollToTopElem>
          </SC.ResultContainer>
          <SC.OpenSearchDescription ref={OSDescRef} hidden={history.length > 0}>
            <Box className="first-description">
              <SC.OpenSearchImage image={OpenSearchLogo} />
              <Box display="flex" className="contentTitle">
                <Typography variant="h1" color="primary" className="title">
                  Welcome to Corporatus
                </Typography>
                {/* {!isMobileResolution() ? <>&nbsp;&nbsp;</> : null}
                <Typography variant="h1" className="title brand">
                  Corporatus
                </Typography> */}
              </Box>
              {/* <Typography color="primary" className="subTitle">
                Research Agent
              </Typography> */}
              <CardMedia
                className="mainChatImage"
                component="img"
                src={MainChatImage}
                title="Main Chat Image"
              />
            </Box>
            <SC.AdSenseContainer
              style={{
                maxHeight: "100px !important",
                background: "transparent",
                padding: "unset",
              }}
            >
              <Adsense
                className="adsbygoogle"
                client="ca-pub-6458368253572204"
                slot="8791689159"
                format="auto"
                adTest="on"
                responsive="true"
                style={{
                  maxHeight: "100px !important",
                  height: "90px",
                  width: "728px",
                  display: "inline-block",
                  bottom: "0px",
                  margin: "auto",
                }}
              />
            </SC.AdSenseContainer>
          </SC.OpenSearchDescription>
          <SC.InputSearch
            hideSearchBar={false}
            clearInputE={clearInput}
            doSearch={setTermToSearch}
            placeholder="How can I assist you today?"
            isMultilines
            startIcon={
              <SC.IconButton
                name=""
                type="button"
                icon={<SC.StartTutoIcon />}
                onClick={startTuto}
                disabled={disabled}
              />
            }
            endIcon={
              <SearchButton
                type="submit"
                customClasses={classes.searchButton}
              />
            }
            hideClearButton
            disabled={disabled}
            inputRef={inputRef}
            autoTyping={typingSimulation}
            customClass={currentContentIndex === 1 ? classes.tutoActived : ""}
          />
          <InfoTooltip
            open={openTooltip}
            eleRef={tooltipElemRef}
            content={
              showToolTip ? (
                <TutorialContent
                  content={tooltipContent}
                  successHandler={successTooltipHandler}
                  cancelHandler={cancelTooltipHandler}
                />
              ) : (
                <GenericInfoCard
                  data={trialInfoData}
                  buttonHandler={() => {
                    // console.debug("=====> trial info handler");
                    if (!userHasActiveSubscription) {
                      handleBillingModal();
                      setOpenTooltip(false);
                      return;
                    }
                    setOpenTooltip(false);
                    setRedirectToManage(true);
                  }}
                />
              )
            }
            successHandler={successTooltipHandler}
            cancelHandler={cancelTooltipHandler}
            showArrow={showTooltipArrow}
            isModal={currentContentIndex === 0 || showToolTip}
            customClasses={
              currentContentIndex !== 1 ? classes.customDialog : ""
            }
          />
        </SC.Container>
        {toRegister ? <Redirect to="/signup" /> : null}
        <Dialog open={showLogin}>
          <SignInComponent />
        </Dialog>
        <Snackbar
          open={!!snackBarMessage}
          autoHideDuration={snackBarMessage?.delay ?? 3000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={snackBarMessage?.severity}
            message={snackBarMessage?.message}
          />
        </Snackbar>
        <BilingModal
          openModal={openBillingModal}
          planData={chatProPlanData as AudionautPlanFragmentFragment | null}
          createSubscription={createSubscription}
          handleCloseModal={handleBillingModal}
          currentAddressData={currentAddress}
          paymentMethodData={currentPaymentMethod}
          chatPro
        />
      </SC.OpenSearchContainer>
      <Drawer
        hidden={isMobileResolution()}
        anchor="right"
        open={openInsight}
        onClose={() => {
          // console.debug("=====> on close insights");
        }}
      >
        <SC.InsightContainer ref={insightRef}>
          <Typography
            variant="h2"
            color="primary"
            align="left"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Insight Repository
            <SC.CloseInsightsButton
              icon={<CloseOutlinedIcon />}
              type="button"
              onClick={() => {
                setOpenInsight(false);
              }}
              name=""
            />
          </Typography>
          <SC.InsightTabsContainer value={tabShowed}>
            <SC.InsightTabs
              variant="fullWidth"
              aria-label="simple tabs example"
              onChange={handleTabChange}
            >
              <SC.InsightTab label="History" value="1" />
              <SC.InsightTab label="Graphs" value="2" />
            </SC.InsightTabs>
            <SC.InsightTabContent value="1">
              <ChatTopicsList
                loading={getChatHistoricLoading}
                items={chatHistoric}
                onOpen={openMessagesByTopic}
              />
            </SC.InsightTabContent>
            <SC.InsightTabContent value="2">
              {sourcesImages != null ? (
                <FiguresListComponet
                  images={sourcesImages}
                  onDiscussClick={onDiscussHandler}
                />
              ) : (
                <SC.NoResults
                  show
                  title="Open Detail"
                  description="This dialogue shows images associated with a given response."
                />
              )}
            </SC.InsightTabContent>
            <SC.AdSenseContainer
              style={{
                background: "transparent",
              }}
            >
              <Adsense
                className="adsbygoogle"
                client="ca-pub-6458368253572204"
                slot="9183421450"
                format="auto"
                adTest="on"
                responsive="true"
                style={{
                  maxHeight: "250px !important",
                  width: "calc(80% + 24px)",
                  display: "block",
                  bottom: "0px",
                  margin: "auto",
                }}
              />
            </SC.AdSenseContainer>
          </SC.InsightTabsContainer>
        </SC.InsightContainer>
      </Drawer>
      <SC.MobileInsights
        hidden={!isMobileResolution()}
        open={openInsight}
        onOpen={() => {
          // console.debug("onOpen");
        }}
        onClose={() => {
          // console.debug("onClose");
        }}
      >
        <SC.InsightContainer ref={insightRef}>
          <Typography
            variant="h2"
            color="primary"
            align="left"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            Insight Repository
            <SC.CloseInsightsButton
              icon={<CloseOutlinedIcon />}
              type="button"
              onClick={() => {
                setOpenInsight(false);
              }}
              name=""
            />
          </Typography>
          <SC.InsightTabsContainer value={tabShowed}>
            <SC.InsightTabs
              variant="fullWidth"
              aria-label="simple tabs example"
              onChange={handleTabChange}
            >
              <SC.InsightTab label="History" value="1" />
              <SC.InsightTab label="Graphs" value="2" />
            </SC.InsightTabs>
            <SC.InsightTabContent value="1">
              <ChatTopicsList
                loading={getChatHistoricLoading}
                items={chatHistoric}
                onOpen={openMessagesByTopic}
              />
            </SC.InsightTabContent>
            <SC.InsightTabContent value="2">
              {sourcesImages != null ? (
                <FiguresListComponet
                  images={sourcesImages}
                  onDiscussClick={onDiscussHandler}
                />
              ) : (
                <SC.NoResults
                  show
                  title="Open Detail"
                  description="This dialogue shows images associated with a given response."
                />
              )}
            </SC.InsightTabContent>
            <SC.AdSenseContainer
              style={{
                background: "transparent",
              }}
            >
              <Adsense
                className="adsbygoogle"
                client="ca-pub-6458368253572204"
                slot="9183421450"
                format="auto"
                adTest="on"
                responsive="true"
                style={{
                  maxHeight: "250px !important",
                  width: "calc(80% + 24px)",
                  display: "block",
                  bottom: "0px",
                  margin: "auto",
                }}
              />
            </SC.AdSenseContainer>
          </SC.InsightTabsContainer>
        </SC.InsightContainer>
      </SC.MobileInsights>
      {redirectToManage ? <Redirect to="/subscription/manage" /> : null}
    </SC.MainContainer>
  );
};

export default PublicSearchPage;
