/* eslint-disable react/jsx-props-no-spreading */
import React, { forwardRef } from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import {
  Box,
  List,
  Typography,
  ListItemIcon,
  Tooltip,
  SvgIcon,
  ListItemText,
  ListItem,
} from "@material-ui/core";

import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import PersonIcon from "@material-ui/icons/Person";

import UserProfilePicture from "components/user-profile-picture/user-profile-picture.component";

import { useUserDataStore } from "commons/utils/user-data";

import { ReactComponent as billingIcon } from "assets/icons/payments-icon.svg";
import { ReactComponent as ReferralIcon } from "assets/icons/gift_icon.svg";
import { ReactComponent as PaymentMethodIcon } from "../../assets/icons/paymentMethod-icon.svg";
import { ReactComponent as SubscriptionIcon } from "../../assets/icons/subscription-icon.svg";

import SC from "./mobile-navigation-menu.styles";

const MobileNavigationMenu: React.FC = () => {
  const classes = SC.generalStyles();
  const userData = useUserDataStore((state: any) => state.userData);
  const uuidTemp = React.useMemo(() => userData.uuid, [userData]);
  const userDataLocal = React.useMemo(() => userData, [userData]);

  return (
    <Box>
      <SC.ProfileData component="div">
        <SC.ProfileImage
          name="profilePicture"
          urlPicture={userDataLocal.profilePicture!}
          isEditable={false}
          onChange={() => {}}
        />
        <Box component="div">
          <Typography className="nameLbl">
            {userDataLocal.firstName} {userDataLocal.lastName}
          </Typography>
          <Typography className="companyLbl">
            {userDataLocal.email ? userDataLocal.email : "-"}
          </Typography>
        </Box>
      </SC.ProfileData>
      <List component="div" className={classes.LinksContainer} disablePadding>
        <ListItem
          button
          component={forwardRef((props: NavLinkProps, ref: any) => (
            <NavLink exact {...props} innerRef={ref} />
          ))}
          className={`${classes.LinkItem}`}
          to="/my-profile"
        >
          <ListItemIcon>
            <Tooltip title="Profile" aria-label="profile-icon">
              <PersonIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            primary="Profile"
            primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
          />
          <ChevronRightIcon />
        </ListItem>
        <br />
        <ListItem
          button
          component={forwardRef((props: NavLinkProps, ref: any) => (
            <NavLink exact {...props} innerRef={ref} />
          ))}
          className={`${classes.LinkItem}`}
          to="/subscription/manage"
        >
          <ListItemIcon>
            <Tooltip title="Subscription" aria-label="Subscription-icon">
              <SvgIcon component={SubscriptionIcon} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            primary="Subscription"
            primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
          />
          <ChevronRightIcon />
        </ListItem>
        <ListItem
          button
          component={forwardRef((props: NavLinkProps, ref: any) => (
            <NavLink exact {...props} innerRef={ref} />
          ))}
          className={`${classes.LinkItem}`}
          to="/subscription/billing-address"
        >
          <ListItemIcon>
            <Tooltip title="Billing Address" aria-label="BillingAdress-icon">
              <SvgIcon component={billingIcon} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            primary="Billing Adress"
            primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
          />
          <ChevronRightIcon />
        </ListItem>
        <ListItem
          button
          component={forwardRef((props: NavLinkProps, ref: any) => (
            <NavLink exact {...props} innerRef={ref} />
          ))}
          className={`${classes.LinkItem}`}
          to="/subscription/payment-methods"
        >
          <ListItemIcon>
            <Tooltip title="Payment Method" aria-label="PaymentMethod-icon">
              <SvgIcon component={PaymentMethodIcon} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            primary="Payment Method"
            primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
          />
          <ChevronRightIcon />
        </ListItem>
        <br />
        <ListItem
          button
          component={forwardRef((props: NavLinkProps, ref: any) => (
            <NavLink exact {...props} innerRef={ref} />
          ))}
          className={`${classes.LinkItem}`}
          to="/referrals"
        >
          <ListItemIcon>
            <Tooltip title="My referrals" aria-label="MyReferrals-icon">
              <SvgIcon component={ReferralIcon} />
            </Tooltip>
          </ListItemIcon>
          <ListItemText
            primary="My Referrals"
            primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
          />
          <ChevronRightIcon />
        </ListItem>
      </List>
    </Box>
  );
};

export default MobileNavigationMenu;
