import { Box, Button, List, ListItem, Theme } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/styles";
import UserProfilePicture from "components/user-profile-picture/user-profile-picture.component";

const generalStyles = makeStyles((theme: Theme) => ({
  LinksContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    alignItems: "center",
    justifyContent: "center",
    padding: "14px",
  },
  LinkItem: {
    height: "40px",
    width: "100%",
    color: theme.generalStylesContainer.color,
    border: "1px solid #D4E0E1",
    borderRadius: "10px",
    "& svg": {
      color: "#CCCCCC",
      fill: "#CCCCCC",
    },
    "&.active": {
      background: "#6EA1A33D",
      "& svg": {
        color: "#6EA1A3",
        fill: "#6EA1A3",
      },
      border: "unset",
    },
  },
}));

const ProfileData = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "14px",
    alignItems: "center",
    justifyContent: "center",
    "& .nameLbl": {
      fontSize: "22px",
    },
    "& .companyLbl": {
      fontSize: "12px",
    },
  },
}))(Box);

const OptionsContainer = withStyles((theme: Theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    alignItems: "center",
    justifyContent: "center",
  },
}))(List);

const OptionLink = withStyles((theme: Theme) => ({
  root: {
    height: "40px",
    width: "100%",
    color: theme.generalStylesContainer.color,
    border: "1px solid #D4E0E1",
    "& svg": {
      fill: "#CCCCCC",
    },
    "&.active": {
      background: "#6EA1A33D",
      "& svg": {
        fill: "#6EA1A3",
      },
      border: "unset",
    },
  },
}))(ListItem);

const ProfileImage = withStyles((theme: Theme) => ({
  root: {
    width: "90px !important",
    height: "90px !important",
    margin: "unset",
  },
}))(UserProfilePicture);

export default {
  generalStyles,
  ProfileData,
  OptionsContainer,
  OptionLink,
  ProfileImage,
};
