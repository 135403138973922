import { ThemeOptions } from "@material-ui/core/styles/createTheme";

import chatProBkg_light from "assets/images/chat-pro-bkg_light.png";
import chatProBkg_dark from "assets/images/chat-pro-bkg_dark.png";

import { BASE_FONT_SIZE, adaptativeSmallScreen } from "../theme.utils";

enum FontSizes {
  XSMALL = "10px",
  DEFAULT = "14px",
  SMALL = "16px",
  MEDIUM = "18px",
  MEDIUM1 = "20px",
  LARGE = "26px",
}

enum LineHeight {
  XSMALL = "14px",
  DEFAULT = "18px",
  SMALL = "20px",
  MEDIUM = "22px",
  MEDIUM1 = "24px",
  LARGE = "30px",
}

export const theme: {
  ligth: ThemeOptions;
  dark: ThemeOptions;
} = {
  ligth: {
    authGeneralStyles: {
      container: {
        backgroundColor: "#222222",
      },
      formContainer: {
        backgroundColor: "#FFFFFF",
      },
      logoContainer: {
        backgroundColor: "#161616",
      },
      fontStyles: {
        color: {
          secondary: "#757575",
        },
        placeholder: "#B0B0B0",
      },
    },
    generalStylesContainer: {
      color: "#88A6A0",
      hoverColor: "#6EA1A3",
      chatProBkg: "#FFF",
      chatProBkgImg: `url(${chatProBkg_light})`,
      header: {
        backgroundColor: "#E7EDEC",
        borderColor: "#EAEAEA",
      },
      footer: {
        color: "#FFFFFF",
        backgroundColor: "#666666",
      },
      drawer: {
        color: "#CCCCCC",
        backgroundColor: "#1C2624",
        active: {
          backgroundColor: "#6EA1A33D",
          borderColor: "#6EA1A3",
          color: "#CCCCCC",
        },
        themeSwitch: {
          backgroundColor: "#141C1B",
          borderColor: "#304442",
        },
      },
      logo: {
        backgroundColor: "#1F3B3F",
      },
      layers: {
        main: {
          backgroundColor: "#FAFAFA",
        },
        primary: {
          backgroundColor: "#FFFFFF",
          backgroundColor40: "#FFFFFF66",
          borderColor: "#E1E1E1",
        },
        secondary: {
          backgroundColor: "#E2EAE9",
        },
        content: {
          backgroundColor: "#F7F7F7",
          borderColor: "#D9D9D9",
        },
      },
      chat: {
        brand: "#1F3B3F",
        color: "#373737",
        local: {
          color: "#6B6B6B",
        },
        remote: {
          color: "#5F5F5F",
          color2: "#696969",
        },
        chip: {
          color: "#575757",
          container: {
            backgroundColor1: "#D9C5A64D",
            backgroundColor2: "#5AE3C233",
            backgroundColor3: "#E37B5A1A",
            backgroundColor4: "#B1D1D459",
          },
        },
      },
      referrals: {
        color: {
          main: "#000",
          highlight: "#B8CAC6",
          gold: "#CAAC86",
          green: "#1F3B3F",
          stepper: "#757575",
          cell: "#141C1B",
          complete: "#6EA1A340",
        },
      },
      footerNavigation: {
        color: "#CCCCCC",
        active: "#6EA1A3",
      },
    },
    plans: {
      card: {
        color: "#454545",
        highlight: "#D6BB93",
        header: {
          backgroundColor: "#4B514B",
        },
      },
    },
    fontGeneralStyles: {
      xSmall: {
        fontSize: FontSizes.XSMALL,
        lineHeight: LineHeight.XSMALL,
      },
      default: {
        fontSize: FontSizes.DEFAULT,
        lineHeight: LineHeight.DEFAULT,
      },
      small: {
        fontSize: FontSizes.SMALL,
        lineHeight: LineHeight.SMALL,
      },
      medium: {
        fontSize: FontSizes.MEDIUM,
        lineHeight: LineHeight.MEDIUM,
      },
      medium1: {
        fontSize: FontSizes.MEDIUM1,
        lineHeight: LineHeight.MEDIUM1,
      },
      large: {
        fontSize: FontSizes.LARGE,
        lineHeight: LineHeight.LARGE,
      },
    },
    theming: {
      gray1: {
        color: "#B8B8B8",
      },
      gray: {
        color: "#ABABAB",
      },
      gray2: {
        color: "#B4A7A7",
      },
      hoverIconButton: {
        color: "#B8CAC6",
      },
    },
    typography: {
      fontFamily: "Merriweather",
      fontSize: 16,
      h1: {
        fontSize: "35px",
        fontWeight: 700,
      },
      h2: {
        fontSize: "30px",
        fontWeight: 800,
      },
      h3: {
        fontSize: "24px",
        fontWeight: 800,
      },
      h4: {
        fontSize: "22px",
        fontWeight: 800,
      },
      h5: {
        fontSize: "18px",
        fontWeight: 800,
      },
      h6: {
        fontSize: "16px",
        fontWeight: 700,
      },
      // body1: {
      //   fontSize: FontSizes.LARGE,
      //   fontWeight: 800,
      // },
    },
    palette: {
      primary: {
        main: "#88A6A0",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#ADADAD",
        contrastText: "#226fa9",
      },
      success: {
        main: "#40DA99",
      },
      error: {
        main: "#FE6377",
      },
      background: {
        default: "#FAFAFA",
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            fontSize: BASE_FONT_SIZE,
          },
          body: {
            fontSize: "1rem",
            color: "#88A6A0",
            backgroudnColor: "#FAFAFA",
          },
          "*::-webkit-scrollbar": {
            width: "14px",
          },
          "*::-webkit-scrollbar-track": {
            background: "#FAFAFA",
            // border: "unset",
          },
          "*::-webkit-scrollbar-thumb": {
            background: "#9D9D9D",
            borderRadius: "6px",
            borderLeft: "4px solid #FAFAFA",
            borderRight: "4px solid #FAFAFA",
          },
          "input:-webkit-autofill": {
            transition: "background-color 0s 600000s, color 0s 600000s",
          },
          "input:-webkit-autofill:focus": {
            transition: "background-color 0s 600000s, color 0s 600000s",
          },
        },
      },
      MuiButtonBase: {
        root: {
          "&.MuiFab-primary": {
            color: "#FFFFFF",
            backgroundColor: "#88A6A0",
          },
        },
      },
      MuiIconButton: {
        root: {
          color: "black",
        },
      },
      MuiCheckbox: {
        root: {
          color: "#B8CAC6",
          "&.Mui-checked": {
            color: "#B8CAC6",
          },
        },
      },
      MuiRadio: {
        colorSecondary: {
          color: "black",
          "&$checked": {
            color: "black",
          },
        },
      },
      MuiDialog: {
        root: {
          "& .MuiPaper-root": {
            width: "100%",
            background: "#FFF",
          },
        },
      },
      MuiDialogTitle: {
        root: {
          padding: "20px",
          "& h2": {
            color: "#88A6A0",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "27px",
          },
        },
      },
      MuiDialogContent: {
        root: {
          paddingLeft: "20px",
          paddingRight: "20px",
        },
      },
      MuiDialogActions: {
        root: {
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "20px",
        },
      },
      MuiPopover: {
        root: {
          "& .MuiPaper-root": {
            background: "#FFFFFF",
            borderRadius: "10px",
            "& .MuiListItem-root.Mui-selected": {
              backgroundColor: "#E9E6F1",
            },
            "& .MuiListItem-root.Mui-selected:hover": {
              backgroundColor: "#E9E6F1",
            },
            "& .MuiListItem-root": {
              fontSize: "16px",
              lineHeight: "20px",
              color: "#B8CAC6",
            },
          },
        },
      },
      MuiTextField: {
        root: {
          "& .MuiInputBase-root": {
            color: "#88A6A0",
            "& fieldset": {
              borderColor: "#B8CAC6 !important",
            },
            "&:hover fieldset": {
              borderColor: "#6B6B6B",
            },
          },
          "& .MuiFormLabel-root": {
            color: "#ADADAD",
            "&.Mui-focused": {
              color: "#88A6A0",
            },
          },
        },
      },
      MuiInputBase: {
        root: {
          color: "#88A6A0",
          backgroundColor: "#FFFFFF",
          // boxShadow: "0px 2px 10px 0px rgba(146, 128, 187, 0.50)",
          "&:hover": {
            borderColor: "rgba(146, 128, 187, 0.50)",
            // color: "#6B6B6B",
            "& input::placeholder": {
              color: "#B0B0B0 !important",
            },
            "& .MuiButtonBase-root:first-child": {
              color: "#6B6B6B",
            },
          },
          "&.active": {
            borderColor: "#6B6B6B",
            "& input": {
              color: "#88A6A0",
            },
            "& .MuiButtonBase-root:first-child": {
              color: "#6B6B6B",
            },
          },
          "& input::placeholder": {
            color: "#B0B0B0",
          },
        },
      },
      MuiMenuItem: {
        root: {
          "& .MuiPaper-root": {
            background: "#0C686E",
            color: "#88A6A0",
          },
        },
      },
    },
    formats: {
      date: "dd/MM/yyyy",
      datetime: "dd/MM/yyyy HH:mm",
    },
    textField: {
      labelTextColor: "#8940f2",
      input: {
        textColor: "#747474",
        backgroundColor: "#ffffff",
        disabledBackgroundColor: "transparent",
        hoverBackgroundColor: "#ebebeb",
      },
      inputUnderline: {
        borderColor: "#6200ee",
        disabledBorderColor: "#fbfbfb",
        errorBorderColor: "#a80500",
      },
      helperTextColor: "#8940f2",
    },
    taxHistory: {
      padding: "1.14rem",
      accordion: {
        borderBottom: 0,
        display: "none",
        margin: "auto",
        boxShadow: "none",
      },
      header: {
        flexDirection: "row-reverse",
        padding: 0,
        alignItems: "flex-start",
        textAlign: "left",
        content: {
          paddingLeft: "1.14rem",
          paddingTop: ".7rem",
        },
        expandIcon: {
          padding: "1.14rem",
        },
        expanded: {
          margin: 0,
        },
      },
      content: {
        paddingTop: 0,
        paddingLeft: "4rem",
        textAlign: "left",
        height: "70vh",
        overflow: "scroll",
        root: {
          padding: 0,
          marginTop: 0,
          paddingRight: "3rem",
        },
      },
      financialSummary: {
        header: {
          fontSize: FontSizes.MEDIUM,
          fontWeight: "bold",
        },
        subHeader: {
          fontSize: FontSizes.DEFAULT,
          fontWeight: "bold",
        },
      },
    },
    nestedListItem: {
      fontSize: FontSizes.DEFAULT,
    },
    searchBox: {
      labelTextColor: "#3b4d61",
      input: {
        textColor: "#464646",
        border: "#cbd6e2 1px solid",
      },
      inputUnderline: {
        borderColor: "#cbd6e2",
      },
      helperTextColor: "#3b4d61",
      iconColor: "#33485c",
    },
    autofillField: {
      labelTextColor: "#3b4d61",
      input: {
        textColor: "#464646",
        border: "#cbd6e2 1px solid",
      },
      inputUnderline: {
        borderColor: "#cbd6e2",
      },
      helperTextColor: "#3b4d61",
      iconColor: "#33485c",
    },
    pane: {
      backgroundColor: "#f5f5f5",
    },
    table: {
      tableRow: {
        evenRowBackgroundColor: "#9280BB0A !important",
      },
      tablePagination: {
        inputBorder: "none",
        selectIconColor: "#A2A2A2",
        actionsIconColor: "#4581AF",
      },
      toolbarActions: {
        iconColor: "#B8CAC6",
        fontSize: FontSizes.MEDIUM,
        fontWeight: 600,
        lineHeight: LineHeight.MEDIUM,
      },
      toolbar: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      head: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      body: {
        fontSize: FontSizes.DEFAULT,
      },
      footer: {
        fontSize: FontSizes.SMALL,
      },
      link: {
        textDecoration: "underline",
        color: "#141C1B",
      },
      icon: {
        default: {
          color: "#cbd6e2",
        },
      },
      IconButton: {
        padding: 0,
        paddindWithLef: "0 0 0 12px",
      },
      title: {
        fontSize: FontSizes.LARGE,
        fontWeight: "bold",
      },
    },
    userProfilePicture: {
      width: 200,
      height: 200,
      defaultColor: "#2e3f50",
    },
    popOverButton: {
      backgroundColor: "#f5f8fa",
    },
    progressIndicator: {
      backgroundColor: "rgba(0,0,0,.1)",
    },
    accordionHeader: {
      mainHeader: {
        fontSize: FontSizes.LARGE,
      },
      groupHeader: {
        fontSize: FontSizes.SMALL,
      },
    },
    accordionBody: {
      backgroundColor: "#FFFFFF",
      title: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },
      subtitle: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      labelButton: {
        fontSize: "0.ß8rem",
      },
    },
    breadCrumps: {
      fontSize: FontSizes.SMALL,
    },
    switch: {
      fontSize: FontSizes.SMALL,
    },
    addresses: {
      title: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
        color: "",
      },
      body: {
        fontSize: FontSizes.DEFAULT,
      },
    },
    notes: {
      title: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      body: {
        fontSize: FontSizes.SMALL,
      },
      toolbar: {
        justifyContent: "space-between",
        fontSize: "1.42rem",
        fontWeight: "bold",
        padding: 0,
        minHeight: 0,
      },
      toolbarAction: {
        color: "#4681AF",
        fontSize: "1.42rem",
        marginRight: "1.14rem",
        display: "inline-flex",
        alignItems: "center",
      },
      toolbarActionText: {
        color: "#4681AF",
        fontSize: "1.42rem",
        display: "inline",
      },
      authorName: {
        fontSize: FontSizes.SMALL,
      },
      authorProfilePicture: {
        width: "1.71rem",
        height: "1.71rem",
        defaultColor: "#2e3f50",
      },
      attachments: {
        iconButton: {
          padding: 0,
          marginRight: "1.14rem",
          fontSize: "1.42rem",
        },
        attachment: {
          marginRight: "1.14rem",
        },
      },
      container: {
        marginBottom: "1.14rem",
        content: {
          paddingTop: "1.14rem",
          paddingBottom: "1.14rem",
          paddingRight: "2.28rem",
          paddingLeft: "2.28rem",
          timestamp: {
            marginTop: "1.14rem",
          },
          body: {
            paddingTop: "1.14rem",
          },
        },
      },
      tab: {
        textAlign: "left",
        padding: "1.14rem",

        titleAction: {
          display: "inline-flex",
          alignItems: "center",
        },

        title: {
          marginRight: "1.14rem",
        },
      },
    },
    tab: {
      color: "#33475B",
      fontSize: FontSizes.DEFAULT,
    },
    tabPanel: {
      backgroundColor: "red",
    },
    label: {
      color: "#2E3F50",
    },
    scrollbar: {
      backgroundColor: "#2e3f50",
    },
    selectField: {
      fontSize: "1.1rem",
    },
    border: {
      color: "rgb(0,0,0, 0.12)",
    },
    banner: {
      height: "7.85rem",
    },
    bannerSmall: {
      height: adaptativeSmallScreen(7.85),
    },
    bannerGraphic: {
      paddingTop: "0.42rem",
      paddingRight: "3.86rem",
      paddingBottom: "0.42rem",
      paddingLeft: "2.28rem",
    },
    bannerGraphicSmall: {
      paddingTop: adaptativeSmallScreen(0.42),
      paddingRight: adaptativeSmallScreen(3.86),
      paddingBottom: adaptativeSmallScreen(0.42),
      paddingLeft: adaptativeSmallScreen(2.28),
    },
    bannerGraphicText: {
      fontWeight: "normal",
      fontSize: FontSizes.SMALL,
      lineHeight: "1.57rem",
      color: "#F7B500",
    },
    bannerGraphicTextSmall: {
      fontSize: adaptativeSmallScreen(1.14),
      lineHeight: adaptativeSmallScreen(1.57),
    },
    navigation: {
      paddingTop: "1.14rem",
      paddingRight: "1.71rem",
      paddingBottom: "1.14rem",
      paddingLeft: "1.71rem",
      fontWeight: "normal",
      borderTopRightRadius: "0.57rem",
      borderTopLeftRadius: "0.57rem",
      fontSize: FontSizes.MEDIUM,
      lineHeight: "2.14rem",
      background: "rgba(255,255,255,0.2)",
    },
    navigationSmall: {
      paddingTop: adaptativeSmallScreen(1.14),
      paddingRight: adaptativeSmallScreen(1.71),
      paddingBottom: adaptativeSmallScreen(1.14),
      paddingLeft: adaptativeSmallScreen(1.71),
      borderTopRightRadius: adaptativeSmallScreen(0.57),
      borderTopLeftRadius: adaptativeSmallScreen(0.57),
      fontSize: adaptativeSmallScreen(1.4286),
      lineHeight: adaptativeSmallScreen(2.14),
    },
    navigationIcons: {
      fontSize: "2.85rem",
    },
    navigationIconsSmall: {
      fontSize: adaptativeSmallScreen(2.85),
    },
    navigationTabActive: {
      background: "#FFFFFF",
      color: "#2E3F50",
    },
    generalSearch: {
      paddingTop: "1.85rem",
      paddingBottom: "1.85rem",
      fontSize: "24px",
      width: "38rem",
    },
    generalSearchSmall: {
      paddingTop: adaptativeSmallScreen(1.85),
      paddingBottom: adaptativeSmallScreen(1.85),
      fontSize: adaptativeSmallScreen(2.85),
      width: adaptativeSmallScreen(38),
    },
    generalSearchActive: {
      color: "#989898",
      fontSize: "2rem",
    },
    generalSearchActiveSmall: {
      fontSize: adaptativeSmallScreen(2),
    },
    userMenu: {
      paddingRight: "2.28rem",
      fontSize: "2.85rem",
      color: "#777A7E",
    },
    userMenuSmall: {
      paddingRight: adaptativeSmallScreen(2.28),
      fontSize: adaptativeSmallScreen(2.85),
      color: "#777A7E",
    },
    leftPane: {
      fontSize: "1.5rem",
    },
    iconButton: {
      fontSize: "0.8rem",
    },
    card: {
      title: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },
      subtitle: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
    },
    selectableList: {
      hoverColor: "rgba(0, 0, 0, 0.04)",
      hoverColorSelected: "rgba(2, 90, 157, 0.95)",
      textColor: "#FFFFFF",
      isSelected: "rgba(2, 90, 157, 0.87)",
      paddingTopTreeItem: 0,
      paddingBottomTreeItem: 0,
      afterBorderLeft: "2rem solid",
      afterBorderTop: "1.70rem solid transparent",
      afterBorderBottom: "1.70rem solid transparent",
    },

    InputLabel: {
      textAlign: "left",
      position: "absolute",
      marginLeft: "35px",
      marginTop: "-15px",
    },

    reports: {
      title: {
        fontWeight: "bold",
        fontSize: "1.71rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        textAlign: "left",
      },
      description: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: "1.14rem",
        paddingBottom: "1.14rem",
        textAlign: "left",
      },
    },

    radioButton: {
      color: "black",
    },

    UtilitiesTab: {
      header: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },
    },
    tableFilter: {
      container: {
        paddingLeft: "1.14rem",
        paddingRight: "1.14rem",
        textAlign: "left",
      },
      label: {
        paddingBottom: "2.28rem",
        fontWeight: "bold",
      },
      box: {
        padding: 0,
        paddingTop: "2.28rem",
      },
      boxButton: {
        padding: 0,
        paddingTop: "2.28rem",
        textAlign: "left",
      },
    },
    button: {
      primary: {
        fontSize: "18px",
        lineHeight: "22px",
        color: "#FFFFFF",
        backgroundColor: "#88A6A0",
        hoverColor: "#FFFFFF",
        hoverBackground: "#688881",
        disabledColor: "#FFFFFF",
        disabledBackground: "#88A6A0",
        textTransform: "capitalize",
      },
      secondary: {
        fontSize: "18px",
        lineHeight: "22px",
        color: "#88A6A0",
        backgroundColor: "#FFFFFF",
        border: "2px solid #88A6A0",
        hoverColor: "#688881",
        hoverBackground: "#FFFFFF",
        hoverBorder: "2px solid #688881",
        disabledColor: "#88A6A0",
        disabledBackground: "#FFFFFF",
        disabledBorder: "2px solid #88A6A0",
        textTransform: "capitalize",
      },
    },
    buttonSize: {
      small: {
        fontWeight: 600,
        height: "38px",
        width: "144px",
        borderRadius: "116px",
      },
      medium: {
        fontWeight: 700,
        height: "42px",
        width: "180px",
        borderRadius: "116px",
      },
      large: {
        fontWeight: 800,
        height: "48px",
        width: "316px",
        borderRadius: "116px",
      },
    },
    containers: {
      general: {
        borderRadius: "10px",
        borderWidth: "1px",
      },
      error: {
        color: "#AF1C3D",
        border: "1px solid #AF1C3D",
        background: "rgba(175, 28, 61, 0.1);",
      },
      warning: {
        color: "#FFA630",
        border: "1px solid #FFA630",
        background:
          "linear-gradient(0deg, rgba(255, 166, 48, 0.1), rgba(255, 166, 48, 0.1))",
      },
    },
    commons: {
      textClipped: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  },
  dark: {
    authGeneralStyles: {
      container: {
        backgroundColor: "#041D20",
      },
      formContainer: {
        backgroundColor: "#073134",
      },
      logoContainer: {
        backgroundColor: "#04161A",
      },
      fontStyles: {
        color: {
          secondary: "#B4A7A7",
        },
        placeholder: "#B0B0B0",
      },
    },
    generalStylesContainer: {
      color: "#FFFFFF",
      hoverColor: "#6EA1A3",
      chatProBkg: "#215D63",
      chatProBkgImg: `url(${chatProBkg_dark})`,
      header: {
        backgroundColor: "#021211",
        borderColor: "#021211",
      },
      footer: {
        color: "#FFFFFF",
        backgroundColor: "#021211",
      },
      drawer: {
        color: "#CCCCCC",
        backgroundColor: "#04161A",
        active: {
          backgroundColor: "#6EA1A326",
          borderColor: "#6EA1A3",
          color: "#CCCCCC",
        },
        themeSwitch: {
          backgroundColor: "#04161A",
          borderColor: "#173E44",
        },
      },
      logo: {
        backgroundColor: "#152D30",
      },
      layers: {
        main: {
          backgroundColor: "#18363C",
        },
        primary: {
          backgroundColor: "#0B4347",
          backgroundColor40: "#0B434766",
          borderColor: "#118F98",
        },
        secondary: {
          backgroundColor: "#0C686E",
        },
        content: {
          backgroundColor: "#07272B",
          borderColor: "#D9D9D9",
        },
      },
      chat: {
        brand: "#E9DDCB",
        color: "#FFFFFF",
        local: {
          color: "#E9E4E4",
        },
        remote: {
          color: "#E9E4E4",
          color2: "#E9E4E4",
        },
        chip: {
          color: "#EAE4E4",
          container: {
            backgroundColor1: "#118F98",
            backgroundColor2: "#6EA1A3",
            backgroundColor3: "#52796F",
            backgroundColor4: "#B1D1D4",
          },
        },
      },
      referrals: {
        color: {
          main: "#FFF",
          highlight: "#B8CAC6",
          gold: "#CAAC86",
          green: "#FFF",
          stepper: "#FFF",
          cell: "#FFF",
          complete: "#6EA1A3",
        },
      },
      footerNavigation: {
        color: "#CCCCCC",
        active: "#6EA1A3",
      },
    },
    plans: {
      card: {
        color: "#FFFFFF",
        highlight: "#D6BB93",
        header: {
          backgroundColor: "#4B514B",
        },
      },
    },
    fontGeneralStyles: {
      xSmall: {
        fontSize: FontSizes.XSMALL,
        lineHeight: LineHeight.XSMALL,
      },
      default: {
        fontSize: FontSizes.DEFAULT,
        lineHeight: LineHeight.DEFAULT,
      },
      small: {
        fontSize: FontSizes.SMALL,
        lineHeight: LineHeight.SMALL,
      },
      medium: {
        fontSize: FontSizes.MEDIUM,
        lineHeight: LineHeight.MEDIUM,
      },
      medium1: {
        fontSize: FontSizes.MEDIUM1,
        lineHeight: LineHeight.MEDIUM1,
      },
      large: {
        fontSize: FontSizes.LARGE,
        lineHeight: LineHeight.LARGE,
      },
    },
    theming: {
      gray1: {
        color: "#B8B8B8",
      },
      gray: {
        color: "#ABABAB",
      },
      gray2: {
        color: "#B4A7A7",
      },
      hoverIconButton: {
        color: "#B8CAC6",
      },
    },
    typography: {
      fontFamily: "Merriweather",
      fontSize: 16,
      h1: {
        fontSize: "35px",
        fontWeight: 700,
      },
      h2: {
        fontSize: "30px",
        fontWeight: 800,
      },
      h3: {
        fontSize: "24px",
        fontWeight: 800,
      },
      h4: {
        fontSize: "22px",
        fontWeight: 800,
      },
      h5: {
        fontSize: "18px",
        fontWeight: 800,
      },
      h6: {
        fontSize: "16px",
        fontWeight: 700,
      },
      // body1: {
      //   fontSize: FontSizes.LARGE,
      //   fontWeight: 800,
      // },
    },
    palette: {
      primary: {
        main: "#FFFFFF",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#6EA1A3",
        contrastText: "#04161A",
      },
      success: {
        main: "#40DA99",
      },
      error: {
        main: "#FE6377",
      },
      background: {
        default: "#FAFAFA",
      },
    },
    overrides: {
      MuiCssBaseline: {
        "@global": {
          html: {
            fontSize: BASE_FONT_SIZE,
          },
          body: {
            fontSize: "1rem",
            color: "#FFFFFF",
            backgroundColor: "#18363C",
          },
          "*::-webkit-scrollbar": {
            width: "14px",
          },
          "*::-webkit-scrollbar-track": {
            background: "#FAFAFA",
            // border: "unset",
          },
          "*::-webkit-scrollbar-thumb": {
            background: "#9D9D9D",
            borderRadius: "6px",
            borderLeft: "4px solid #FAFAFA",
            borderRight: "4px solid #FAFAFA",
          },
          "input:-webkit-autofill": {
            transition: "background-color 0s 600000s, color 0s 600000s",
          },
          "input:-webkit-autofill:focus": {
            transition: "background-color 0s 600000s, color 0s 600000s",
          },
        },
      },
      MuiButton: {
        root: {
          "&.MuiFab-primary": {
            color: "#FFFFFF",
            backgroundColor: "#011110",
          },
        },
      },
      MuiIconButton: {
        root: {
          color: "black",
        },
      },
      MuiCheckbox: {
        root: {
          color: "#FFFFFF",
          "&.Mui-checked": {
            color: "#FFFFFF",
          },
        },
      },
      MuiRadio: {
        colorSecondary: {
          color: "black",
          "&$checked": {
            color: "black",
          },
        },
      },
      MuiDialog: {
        root: {
          "& .MuiPaper-root": {
            background: "#0C686E",
          },
        },
      },
      MuiDialogTitle: {
        root: {
          padding: "20px",
          "& h2": {
            color: "#FFFFFF",
            fontSize: "16px",
            fontWeight: 700,
            lineHeight: "27px",
          },
        },
      },
      MuiDialogContent: {
        root: {
          paddingLeft: "20px",
          paddingRight: "20px",
        },
      },
      MuiDialogActions: {
        root: {
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "20px",
        },
      },
      MuiPopover: {
        root: {
          "& .MuiPaper-root": {
            background: "#0B4347",
            borderRadius: "10px",
            "& .MuiListItem-root.Mui-selected": {
              backgroundColor: "#E9E6F1",
            },
            "& .MuiListItem-root.Mui-selected:hover": {
              backgroundColor: "#E9E6F1",
            },
            "& .MuiListItem-root": {
              fontSize: "16px",
              lineHeight: "20px",
              color: "#6EA1A3",
            },
          },
        },
      },
      MuiTextField: {
        root: {
          "& .MuiInputBase-root": {
            color: "#FFFFFF",
            "& fieldset": {
              borderColor: "#FFFFFF !important",
            },
            "&:hover fieldset": {
              borderColor: "#FFFFFF",
            },
          },
          "& .MuiFormLabel-root": {
            color: "#FFFFFF",
            "&.Mui-focused": {
              color: "#FFFFFF",
            },
          },
        },
      },
      MuiInputBase: {
        root: {
          color: "#CCCCCC",
          backgroundColor: "#0C2A31",
          // boxShadow: "0px 2px 10px 0px rgba(3, 31, 36, 0.50)",
          "&:hover": {
            borderColor: "#CCCCCC",
            // color: "#6B6B6B",
            "& input::placeholder": {
              color: "#CCCCCC !important",
            },
            "& .MuiButtonBase-root:first-child": {
              color: "#CCCCCC",
            },
          },
          "&.active": {
            borderColor: "#CCCCCC",
            "& input": {
              color: "#CCCCCC",
            },
            "& .MuiButtonBase-root:first-child": {
              color: "#CCCCCC",
            },
          },
          "& input::placeholder": {
            color: "#B0B0B0",
          },
        },
      },
      MuiMenuItem: {
        root: {
          "& .MuiPaper-root": {
            background: "#0C686E",
            color: "#FFFFFF",
          },
        },
      },
    },
    formats: {
      date: "dd/MM/yyyy",
      datetime: "dd/MM/yyyy HH:mm",
    },
    textField: {
      labelTextColor: "#FFFFFF",
      input: {
        textColor: "#FFFFFF",
        backgroundColor: "#ffffff",
        disabledBackgroundColor: "transparent",
        hoverBackgroundColor: "#ebebeb",
      },
      inputUnderline: {
        borderColor: "#6200ee",
        disabledBorderColor: "#fbfbfb",
        errorBorderColor: "#a80500",
      },
      helperTextColor: "#8940f2",
    },
    taxHistory: {
      padding: "1.14rem",
      accordion: {
        borderBottom: 0,
        display: "none",
        margin: "auto",
        boxShadow: "none",
      },
      header: {
        flexDirection: "row-reverse",
        padding: 0,
        alignItems: "flex-start",
        textAlign: "left",
        content: {
          paddingLeft: "1.14rem",
          paddingTop: ".7rem",
        },
        expandIcon: {
          padding: "1.14rem",
        },
        expanded: {
          margin: 0,
        },
      },
      content: {
        paddingTop: 0,
        paddingLeft: "4rem",
        textAlign: "left",
        height: "70vh",
        overflow: "scroll",
        root: {
          padding: 0,
          marginTop: 0,
          paddingRight: "3rem",
        },
      },
      financialSummary: {
        header: {
          fontSize: FontSizes.MEDIUM,
          fontWeight: "bold",
        },
        subHeader: {
          fontSize: FontSizes.DEFAULT,
          fontWeight: "bold",
        },
      },
    },
    nestedListItem: {
      fontSize: FontSizes.DEFAULT,
    },
    searchBox: {
      labelTextColor: "#3b4d61",
      input: {
        textColor: "#464646",
        border: "#cbd6e2 1px solid",
      },
      inputUnderline: {
        borderColor: "#cbd6e2",
      },
      helperTextColor: "#3b4d61",
      iconColor: "#33485c",
    },
    autofillField: {
      labelTextColor: "#3b4d61",
      input: {
        textColor: "#464646",
        border: "#cbd6e2 1px solid",
      },
      inputUnderline: {
        borderColor: "#cbd6e2",
      },
      helperTextColor: "#3b4d61",
      iconColor: "#33485c",
    },
    pane: {
      backgroundColor: "#f5f5f5",
    },
    table: {
      tableRow: {
        evenRowBackgroundColor: "#9280BB0A !important",
      },
      tablePagination: {
        inputBorder: "none",
        selectIconColor: "#A2A2A2",
        actionsIconColor: "#4581AF",
      },
      toolbarActions: {
        iconColor: "#B8CAC6",
        fontSize: FontSizes.MEDIUM,
        fontWeight: 600,
        lineHeight: LineHeight.MEDIUM,
      },
      toolbar: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      head: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      body: {
        fontSize: FontSizes.DEFAULT,
      },
      footer: {
        fontSize: FontSizes.SMALL,
      },
      link: {
        textDecoration: "none",
        color: "#FFF",
      },
      icon: {
        default: {
          color: "#cbd6e2",
        },
      },
      IconButton: {
        padding: 0,
        paddindWithLef: "0 0 0 12px",
      },
      title: {
        fontSize: FontSizes.LARGE,
        fontWeight: "bold",
      },
    },
    userProfilePicture: {
      width: 200,
      height: 200,
      defaultColor: "#2e3f50",
    },
    popOverButton: {
      backgroundColor: "#f5f8fa",
    },
    progressIndicator: {
      backgroundColor: "rgba(0,0,0,.1)",
    },
    accordionHeader: {
      mainHeader: {
        fontSize: FontSizes.LARGE,
      },
      groupHeader: {
        fontSize: FontSizes.SMALL,
      },
    },
    accordionBody: {
      backgroundColor: "#FFFFFF",
      title: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },
      subtitle: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      labelButton: {
        fontSize: "0.ß8rem",
      },
    },
    breadCrumps: {
      fontSize: FontSizes.SMALL,
    },
    switch: {
      fontSize: FontSizes.SMALL,
    },
    addresses: {
      title: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
        color: "",
      },
      body: {
        fontSize: FontSizes.DEFAULT,
      },
    },
    notes: {
      title: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
      body: {
        fontSize: FontSizes.SMALL,
      },
      toolbar: {
        justifyContent: "space-between",
        fontSize: "1.42rem",
        fontWeight: "bold",
        padding: 0,
        minHeight: 0,
      },
      toolbarAction: {
        color: "#4681AF",
        fontSize: "1.42rem",
        marginRight: "1.14rem",
        display: "inline-flex",
        alignItems: "center",
      },
      toolbarActionText: {
        color: "#4681AF",
        fontSize: "1.42rem",
        display: "inline",
      },
      authorName: {
        fontSize: FontSizes.SMALL,
      },
      authorProfilePicture: {
        width: "1.71rem",
        height: "1.71rem",
        defaultColor: "#2e3f50",
      },
      attachments: {
        iconButton: {
          padding: 0,
          marginRight: "1.14rem",
          fontSize: "1.42rem",
        },
        attachment: {
          marginRight: "1.14rem",
        },
      },
      container: {
        marginBottom: "1.14rem",
        content: {
          paddingTop: "1.14rem",
          paddingBottom: "1.14rem",
          paddingRight: "2.28rem",
          paddingLeft: "2.28rem",
          timestamp: {
            marginTop: "1.14rem",
          },
          body: {
            paddingTop: "1.14rem",
          },
        },
      },
      tab: {
        textAlign: "left",
        padding: "1.14rem",

        titleAction: {
          display: "inline-flex",
          alignItems: "center",
        },

        title: {
          marginRight: "1.14rem",
        },
      },
    },
    tab: {
      color: "#33475B",
      fontSize: FontSizes.DEFAULT,
    },
    tabPanel: {
      backgroundColor: "red",
    },
    label: {
      color: "#2E3F50",
    },
    scrollbar: {
      backgroundColor: "#2e3f50",
    },
    selectField: {
      fontSize: "1.1rem",
    },
    border: {
      color: "rgb(0,0,0, 0.12)",
    },
    banner: {
      height: "7.85rem",
    },
    bannerSmall: {
      height: adaptativeSmallScreen(7.85),
    },
    bannerGraphic: {
      paddingTop: "0.42rem",
      paddingRight: "3.86rem",
      paddingBottom: "0.42rem",
      paddingLeft: "2.28rem",
    },
    bannerGraphicSmall: {
      paddingTop: adaptativeSmallScreen(0.42),
      paddingRight: adaptativeSmallScreen(3.86),
      paddingBottom: adaptativeSmallScreen(0.42),
      paddingLeft: adaptativeSmallScreen(2.28),
    },
    bannerGraphicText: {
      fontWeight: "normal",
      fontSize: FontSizes.SMALL,
      lineHeight: "1.57rem",
      color: "#F7B500",
    },
    bannerGraphicTextSmall: {
      fontSize: adaptativeSmallScreen(1.14),
      lineHeight: adaptativeSmallScreen(1.57),
    },
    navigation: {
      paddingTop: "1.14rem",
      paddingRight: "1.71rem",
      paddingBottom: "1.14rem",
      paddingLeft: "1.71rem",
      fontWeight: "normal",
      borderTopRightRadius: "0.57rem",
      borderTopLeftRadius: "0.57rem",
      fontSize: FontSizes.MEDIUM,
      lineHeight: "2.14rem",
      background: "rgba(255,255,255,0.2)",
    },
    navigationSmall: {
      paddingTop: adaptativeSmallScreen(1.14),
      paddingRight: adaptativeSmallScreen(1.71),
      paddingBottom: adaptativeSmallScreen(1.14),
      paddingLeft: adaptativeSmallScreen(1.71),
      borderTopRightRadius: adaptativeSmallScreen(0.57),
      borderTopLeftRadius: adaptativeSmallScreen(0.57),
      fontSize: adaptativeSmallScreen(1.4286),
      lineHeight: adaptativeSmallScreen(2.14),
    },
    navigationIcons: {
      fontSize: "2.85rem",
    },
    navigationIconsSmall: {
      fontSize: adaptativeSmallScreen(2.85),
    },
    navigationTabActive: {
      background: "#FFFFFF",
      color: "#2E3F50",
    },
    generalSearch: {
      paddingTop: "1.85rem",
      paddingBottom: "1.85rem",
      fontSize: "24px",
      width: "38rem",
    },
    generalSearchSmall: {
      paddingTop: adaptativeSmallScreen(1.85),
      paddingBottom: adaptativeSmallScreen(1.85),
      fontSize: adaptativeSmallScreen(2.85),
      width: adaptativeSmallScreen(38),
    },
    generalSearchActive: {
      color: "#989898",
      fontSize: "2rem",
    },
    generalSearchActiveSmall: {
      fontSize: adaptativeSmallScreen(2),
    },
    userMenu: {
      paddingRight: "2.28rem",
      fontSize: "2.85rem",
      color: "#FFF",
    },
    userMenuSmall: {
      paddingRight: adaptativeSmallScreen(2.28),
      fontSize: adaptativeSmallScreen(2.85),
      color: "#FFF",
    },
    leftPane: {
      fontSize: "1.5rem",
    },
    iconButton: {
      fontSize: "0.8rem",
    },
    card: {
      title: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },
      subtitle: {
        fontSize: FontSizes.SMALL,
        fontWeight: "bold",
      },
    },
    selectableList: {
      hoverColor: "rgba(0, 0, 0, 0.04)",
      hoverColorSelected: "rgba(2, 90, 157, 0.95)",
      textColor: "#FFFFFF",
      isSelected: "rgba(2, 90, 157, 0.87)",
      paddingTopTreeItem: 0,
      paddingBottomTreeItem: 0,
      afterBorderLeft: "2rem solid",
      afterBorderTop: "1.70rem solid transparent",
      afterBorderBottom: "1.70rem solid transparent",
    },

    InputLabel: {
      textAlign: "left",
      position: "absolute",
      marginLeft: "35px",
      marginTop: "-15px",
    },

    reports: {
      title: {
        fontWeight: "bold",
        fontSize: "1.71rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        textAlign: "left",
      },
      description: {
        paddingLeft: "1rem",
        paddingRight: "1rem",
        paddingTop: "1.14rem",
        paddingBottom: "1.14rem",
        textAlign: "left",
      },
    },

    radioButton: {
      color: "black",
    },

    UtilitiesTab: {
      header: {
        fontSize: FontSizes.MEDIUM,
        fontWeight: "bold",
      },
    },
    tableFilter: {
      container: {
        paddingLeft: "1.14rem",
        paddingRight: "1.14rem",
        textAlign: "left",
      },
      label: {
        paddingBottom: "2.28rem",
        fontWeight: "bold",
      },
      box: {
        padding: 0,
        paddingTop: "2.28rem",
      },
      boxButton: {
        padding: 0,
        paddingTop: "2.28rem",
        textAlign: "left",
      },
    },
    button: {
      primary: {
        fontSize: "18px",
        lineHeight: "22px",
        color: "#FFFFFF !important",
        backgroundColor: "#011110",
        hoverColor: "#FFFFFF",
        hoverBackground: "#000101",
        disabledColor: "#FFFFFF",
        disabledBackground: "#061F1D",
        textTransform: "capitalize",
      },
      secondary: {
        fontSize: "18px",
        lineHeight: "22px",
        color: "#04161A",
        backgroundColor: "#6EA1A3",
        border: "2px solid #6EA1A3",
        hoverColor: "#04161A",
        hoverBackground: "#26696C",
        hoverBorder: "2px solid #6EA1A3",
        disabledColor: "#18363D",
        disabledBackground: "#A3C8CA",
        disabledBorder: "2px solid #A3C8CA",
        textTransform: "capitalize",
      },
    },
    buttonSize: {
      small: {
        fontWeight: 600,
        height: "38px",
        width: "144px",
        borderRadius: "116px",
      },
      medium: {
        fontWeight: 700,
        height: "42px",
        width: "180px",
        borderRadius: "116px",
      },
      large: {
        fontWeight: 800,
        height: "48px",
        width: "316px",
        borderRadius: "116px",
      },
    },
    containers: {
      general: {
        borderRadius: "10px",
        borderWidth: "1px",
      },
      error: {
        color: "#AF1C3D",
        border: "1px solid #AF1C3D",
        background: "rgba(175, 28, 61, 0.1);",
      },
      warning: {
        color: "#FFA630",
        border: "1px solid #FFA630",
        background:
          "linear-gradient(0deg, rgba(255, 166, 48, 0.1), rgba(255, 166, 48, 0.1))",
      },
    },
    commons: {
      textClipped: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      },
    },
  },
};
