// Dependencies
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// Components
import {
  Box,
  Collapse,
  Paper,
  Slide,
  SvgIcon,
  Typography,
} from "@material-ui/core";
import useScrollTrigger from "@mui/material/useScrollTrigger";

import Navigation from "components/navigation/navigation.component";
import GeneralSearch from "components/general-search/general-search.component";
import UserMenu from "components/user-menu/user-menu.component";
import {
  AppPermissionValidator,
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";
import NotificationHeader from "components/notification-header/notification-header.component";
import ButtonPrimary from "components/button-primary/button-primary.component";
import ButtonSecondary from "components/button-secondary/button-secondary.component";
import BannerGraphic from "components/banner-graphic/banner-graphic.component";
import SearchButton from "components/search-button/search-button.component";
import IconButtonGeneric from "components/icon-button/icon-button.component";
import ThemeSwitchComponent from "components/theme-switch/theme-switch.component";

import { isMobileResolution } from "commons/utils/device-info.util";

// Assets
import SC from "./banner-navigation.styles";

interface SearchGeneralProps {
  doSearch?: any;
  hideSearchBar: boolean;
  finishSearch?: boolean;
  isLogged: boolean;
  openRegister?: (arg0: boolean) => void;
  openLogin?: (arg0: boolean) => void;
  collapsable?: boolean;
}

const BannerNavigation: React.FC<SearchGeneralProps> = ({
  doSearch,
  hideSearchBar = false,
  finishSearch = false,
  isLogged = false,
  openRegister = null,
  openLogin = null,
  collapsable = false,
}) => {
  const history: any = useHistory();
  const [clearInput, setClearInput] = useState(false);
  const [collapsed, setCollapsed] = useState<boolean>(false);

  React.useEffect(() => {
    setClearInput(finishSearch);
  }, [finishSearch]);

  const goHome = () => {
    // setClearInput(true);
    history.push("summary-statistics");
    // setTimeout(() => {
    //   setClearInput(false);
    // }, 300);
  };

  const trigger = useScrollTrigger();

  React.useEffect(() => {
    setCollapsed(trigger);
  }, [trigger]);

  return (
    <Box
      position={collapsable || isMobileResolution() ? "fixed" : "relative"}
      width="100%"
      zIndex={2}
    >
      <Collapse
        in={collapsable && !collapsed}
        collapsedSize={collapsable ? 60 : 60}
      >
        <Paper elevation={4}>
          <SC.BannerNavigation
            height={collapsable && collapsed ? 60 : 60}
            color="primary"
          >
            <ThemeSwitchComponent minimized={false} />
            <SC.HeaderTitle color="primary">Corporatus</SC.HeaderTitle>
            <Box width="60px" />
            {/* {isLogged ? (
              <UserMenu />
            ) : (
              <>
                <BannerGraphic goHome={goHome} open blackLogo />
                <SC.ButtonsContent>
                  <ButtonPrimary
                    onClick={() => {
                      if (openLogin) openLogin(true);
                    }}
                  >
                    Login
                  </ButtonPrimary>
                  <SC.RegisterButton
                    onClick={() => {
                      if (openRegister) openRegister(true);
                    }}
                  >
                    Sign Up
                  </SC.RegisterButton>
                </SC.ButtonsContent>
              </>
            )} */}
          </SC.BannerNavigation>
        </Paper>
      </Collapse>
    </Box>
  );
};

export default BannerNavigation;
