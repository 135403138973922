// Dependencies
import { withStyles, Box, Theme, Switch, Typography } from "@material-ui/core";

// Assets
import bannerUgandaArms from "assets/images/Mask_Group_73.png";
import bannerBackground from "assets/images/Rectangle_Copy_12.png";
import ButtonSecondary from "components/button-secondary/button-secondary.component";

const BannerNavigation = withStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    fontSize: "initial",
    backgroundColor: theme.generalStylesContainer.header.backgroundColor,
    borderBottom: `1px solid ${theme.generalStylesContainer.header.borderColor}`,
    padding: "0px 16px",
    // [theme.breakpoints.down("xl")]: {
    //   height: theme.bannerSmall.height,
    // },
    // [theme.breakpoints.up("xl")]: {
    //   height: theme.banner.height,
    // },
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    // "& form": {
    //   height: "108px",
    // },
    [theme.breakpoints.down(540)]: {
      backgroundColor: theme.generalStylesContainer.layers.main.backgroundColor,
      border: "unset",
    },
  },
}))(Box);

const ButtonsContent = withStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "end",
    columnGap: "10px",
    flex: "1",
  },
}))(Box);

const RegisterButton = withStyles(() => ({
  root: {
    background: "transparent",
    "&:hover": {
      background: "#FFF",
    },
  },
}))(ButtonSecondary);

const HeaderTitle = withStyles((theme: Theme) => ({
  root: {
    flex: "1",
  },
}))(Typography);

export default {
  BannerNavigation,
  ButtonsContent,
  RegisterButton,
  HeaderTitle,
};
