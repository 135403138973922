/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
// import { withStyles } from "@material-ui/core";
import React from "react";
import {
  Container,
  withStyles,
  Box,
  ContainerProps,
  makeStyles,
} from "@material-ui/core";
import { useLocation } from "react-router-dom";

interface CustomContainerProps extends ContainerProps {
  maxHeight?: number;
}

const useContainerStyles = makeStyles((theme) => ({
  root: {
    padding: 0,
    display: "flex",
    overflow: "auto",
    maxHeight: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
    "& .pageContent": {
      flex: "1",
    },
    [theme.breakpoints.down(540)]: {
      maxHeight: "unset !important",
      flexDirection: "column-reverse",
    },
  },
  content: {
    position: "relative",
    padding: 0,
    // paddingTop: "100px",
    // display: "flex",
    overflow: "hidden",
    // minHeight: "calc(100% - 108px)",
    maxHeight: (props: CustomContainerProps) =>
      props.maxHeight ? props.maxHeight : "inherit",
    height: "100%",
    flexDirection: "row",
    "&.chat-content": {
      height: "100% !important",
      "& .chat-main-container": {
        height: "100% !important",
      },
    },
    [theme.breakpoints.down(540)]: {
      flex: "1",
      maxHeight: "calc(100vh - 60px) !important",
      paddingTop: "60px",
      "&.chat-content": {
        maxHeight: "100% !important",
        height: "100% !important",
      },
    },
  },
}));

const PageContent = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => {
    const location = useLocation();
    const urlLocation = location.pathname.split("/");
    const { maxHeight, ...muiProps } = props;
    const classes = useContainerStyles(props);
    return (
      <Container
        className={`${classes.content} ${
          urlLocation[1] === "public-search" ||
          urlLocation[1] === "summary-statistics"
            ? "chat-content"
            : ""
        }`}
        {...muiProps}
        ref={ref}
      />
    );
  }
);

const Page = React.forwardRef<HTMLDivElement, CustomContainerProps>(
  (props, ref) => {
    const { maxHeight, ...muiProps } = props;
    const classes = useContainerStyles(props);

    return <Container className={classes.root} {...muiProps} ref={ref} />;
  }
);

export default {
  Page,
  PageContent,
};
