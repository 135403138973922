/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import {
  Switch,
  Route,
  useRouteMatch,
  RouteProps,
  Redirect,
  useLocation,
  useParams,
} from "react-router-dom";
import { CSSProperties } from "@material-ui/styles";

import { useWindowHeight } from "@react-hook/window-size";

import {
  useGetCurrentSubscriptionInfoQuery,
  useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery,
  UserFragmentFragment,
  useUpdateUserDataMutation,
} from "graphql/types-and-hooks";
import { CLIENT_NAME } from "graphql/client";
import {
  useGetSourcesQuery,
  useGetVideosQuery,
} from "graphql/explore/types-and-hooks";

// Components
import BannerNavigation from "components/banner-navigation/banner-navigation.component";
import Footer from "components/footer/footer.component";

// Pages
import MyProfilePage from "pages/my-profile/my-profile.component";
import UserManagementPage from "pages/user-management/user-management.component";
import GroupManagementPage from "pages/group-management/group-management.component";
import RoleManagementPage from "pages/role-management/role-management.component";
import OrganizationMagamentPage from "pages/organizations/organization-management.component";
import VisibilityPage from "pages/visibility/visibility.component";
import GeneralSearchPage from "pages/general-search/general-search.component";
import SummaryStatisticsPage from "pages/summary-statistics/summary-statistics.component";
import AudioSourcesPage from "pages/audio-sources/audio-sources.component";
import BehaviorsPage from "pages/behaviors/behaviors.component";
import TargetGroupsPage from "pages/target-groups/target-groups.component";
import BehaviorsResultsPage from "pages/behaviors-results/behaviors-results.component";
import PlansDiscountsPage from "pages/plans-discounts/plans-discounts.component";
import CrudTopicsPage from "pages/crud-topics/crud-topics.component";
import ChatHistoryPage from "pages/chat-history/chat-history.component";
import PaymentMethodScreen from "pages/payment-management/views/pages/payment_method_screen";
import SubscriptionBillingPage from "pages/subscription-billing/subscription-billing.component";
import BillingAddressScreen from "pages/payment-management/views/pages/billing_address_screen";
import UserPlansPage from "pages/user-plans/user-plans.component";
import hasProductOwnerRole from "components/app-permission-validator/fetch_current_user_profile";
import PublicSearchPage from "pages/public-search/public-search.component";
import ReferralSystemPage from "pages/referral-system/referral-system.component";
import MobileNavigationMenu from "pages/mobile-navigation-menu/mobile-navigation-menu.component";
import {
  APP_PERMISSION,
  useAppPermissionValidator,
} from "components/app-permission-validator/app-permission-validator.component";
import InfoTooltip from "components/info-tooltip/info-tooltip.component";
import HearAboutAudionaut from "components/hear-about-audionaut/hear-about-audionaut.component";

import TagManager from "react-gtm-module";

// Assets
import {
  Box,
  createStyles,
  makeStyles,
  Snackbar,
  Theme,
} from "@material-ui/core";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";
import Navigation from "components/navigation/navigation.component";
import { isMobileResolution } from "commons/utils/device-info.util";
import { useUserDataStore } from "commons/utils/user-data";
import Alert, { AlertProps } from "components/alert/alert.component";
import { useAppErrorHandler } from "errors/app.errors";

import { HandleGlobalEvents } from "../../commons/utils/handle-global-events";

import SC from "./main.styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    hide: {
      display: "none",
    },
    loader: {
      position: "absolute",
      display: "flex",
      height: "89%",
      width: "100%",
      background: "#0000004D",
    },
    circularProgress: {
      margin: "auto",
    },
    mainContainer: {
      display: "flex",
      flexDirection: "column",
      overflowX: "auto",
    },
    aboutAudionaut: {
      zIndex: "2000 !important" as CSSProperties["zIndex"],
      "& .MuiDialogContent-root": {
        borderRadius: "11px !important",
        padding: "20px 20px 33px !important",
      },
    },
  })
);

interface MainInterface {
  planPreSelected?: string | null;
}

const MainPage: React.FC<MainInterface> = ({ planPreSelected = "" }) => {
  // console.debug("=====> plan pre selected: ", planPreSelected);
  const pageRef = React.useRef<HTMLDivElement>(null);
  const pageContentRef = React.useRef<HTMLDivElement>(null);

  const [removeStylesTimes, setRemovedStylesTimes] = React.useState(0);
  const [intervalId, setIntervalId] = React.useState<any>(-1);

  const [searchTerm, setSearchTerm] = React.useState("");
  const [menuOpened, setMenuOpened] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [clearInputSearch, setClearInputSearch] = React.useState(false);
  const [collapseDrawer, setCollapseDrawer] = React.useState(false);
  const [openAboutAudionaut, setOpenAboutAudionaut] = React.useState(false);
  const [updateStatus, setUpdateStatus] = React.useState<AlertProps>();

  const userData = useUserDataStore((state: any) => state.userData);
  const userDataLocal = React.useMemo(() => userData, [userData]);

  const localClasses = useStyles();

  const pageRefPosition = pageRef.current?.getBoundingClientRect();
  const pageContentPosition = pageContentRef.current?.getBoundingClientRect();

  const pagePositionY = pageRefPosition?.y ?? 0;
  const pageContentPositionY = pageContentPosition?.y ?? 0;

  const windowHeight = useWindowHeight();
  const pageHeight = windowHeight - pagePositionY;
  const pageContentHeight = windowHeight - pageContentPositionY;

  const match = useRouteMatch();

  const location = useLocation();
  const urlLocation = location.pathname.split("/");

  const appPermissionValidator = useAppPermissionValidator();

  const isProductOwner = hasProductOwnerRole();

  const {
    data: currentSubscriptionData,
    loading: currentSubscriptionLoading,
    error: currentSubscriptionError,
    refetch: currentSubscriptionRefetch,
  } = useGetCurrentUserOrganisationCurrentPeriodSubscriptionQuery();

  const onlyChatPro = React.useMemo(() => {
    let isChatProPlan = false;
    if (
      currentSubscriptionData &&
      currentSubscriptionData.getCurrentUserOrganisationCurrentPeriodSubscription &&
      currentSubscriptionData
        .getCurrentUserOrganisationCurrentPeriodSubscription.audionautPlan
    ) {
      const {
        getCurrentUserOrganisationCurrentPeriodSubscription: {
          audionautPlan: { stripeProductId },
        },
      } = currentSubscriptionData;
      isChatProPlan = stripeProductId === "prod_OB5AtO7oe0iEFr";
    }
    return isChatProPlan;
  }, [currentSubscriptionData]);

  const [
    updateUserData,
    { loading: updateUserDataLoading, error: updateUserErro },
  ] = useUpdateUserDataMutation();

  const errorHandler = useAppErrorHandler(updateUserErro);

  const routes: RouteProps[] = [
    {
      path: `${match.path}my-profile`,
      exact: true,
      strict: true,
      component: MyProfilePage,
    },
    {
      path: `${match.path}user-menu`,
      exact: true,
      strict: true,
      component: MobileNavigationMenu,
    },
    {
      path: `${match.path}referrals`,
      exact: true,
      strict: true,
      component: ReferralSystemPage,
    },
    {
      path: `${match.path}public-search`,
      exact: true,
      strict: true,
      component: PublicSearchPage,
    },
  ];

  const baseRoutes: RouteProps[] = [
    {
      path: `${match.path}my-profile`,
      exact: true,
      strict: true,
      component: MyProfilePage,
    },
    {
      path: `${match.path}user-menu`,
      exact: true,
      strict: true,
      component: MobileNavigationMenu,
    },
    {
      path: `${match.path}referrals`,
      exact: true,
      strict: true,
      component: ReferralSystemPage,
    },
    {
      path: `${match.path}public-search`,
      exact: true,
      strict: true,
      component: PublicSearchPage,
    },
  ];

  if (appPermissionValidator) {
    if (
      !onlyChatPro &&
      (isProductOwner || appPermissionValidator(APP_PERMISSION.HOME_VIEW))
    ) {
      routes.push({
        path: `${match.path}`,
        exact: true,
        strict: true,
        component: SummaryStatisticsPage,
      });
    }

    if (
      !onlyChatPro &&
      (isProductOwner ||
        appPermissionValidator(APP_PERMISSION.EXPLORE_VIEW_AUDIOS))
    ) {
      routes.push({
        path: `${match.path}explore/audio-sources`,
        exact: true,
        strict: true,
        component: AudioSourcesPage,
      });
    }

    // if (
    //   !onlyChatPro &&
    //   (isProductOwner || appPermissionValidator(APP_PERMISSION.HOME_VIEW))
    // ) {
    // routes.push({
    //   path: `${match.path}explore/audio-sources`,
    //   exact: true,
    //   strict: true,
    //   component: AudioSourcesPage,
    // });
    // }

    if (
      !onlyChatPro &&
      (isProductOwner || appPermissionValidator(APP_PERMISSION.HOME_VIEW))
    ) {
      const SummaryStatisticsRoute = {
        path: `${match.path}summary-statistics`,
        exact: true,
        strict: true,
        component: SummaryStatisticsPage,
      };
      routes.push(SummaryStatisticsRoute);
      baseRoutes.push(SummaryStatisticsRoute);
    }

    if (
      !onlyChatPro &&
      (isProductOwner ||
        appPermissionValidator(APP_PERMISSION.ANALYZE_USE_SEARCH))
    ) {
      routes.push({
        path: `${match.path}analyze/search`,
        exact: true,
        strict: true,
        component: GeneralSearchPage,
      });
    }

    if (
      !onlyChatPro &&
      (isProductOwner ||
        appPermissionValidator(APP_PERMISSION.ANALYZE_VIEW_BEHAVIORS_SETTINGS))
    ) {
      routes.push({
        path: `${match.path}analyze/behaviors`,
        exact: true,
        strict: true,
        component: BehaviorsPage,
      });
    }

    if (
      !onlyChatPro &&
      (isProductOwner ||
        appPermissionValidator(APP_PERMISSION.ANALYZE_SEE_BEHAVIORS_RESULTS))
    ) {
      routes.push({
        path: `${match.path}analyze/behaviors/results`,
        exact: true,
        strict: true,
        component: BehaviorsResultsPage,
      });
    }

    if (
      !onlyChatPro &&
      (isProductOwner ||
        appPermissionValidator(APP_PERMISSION.TARGET_VIEW_TARGET_GROUPS))
    ) {
      routes.push({
        path: `${match.path}target/groups`,
        exact: true,
        strict: true,
        component: TargetGroupsPage,
      });
    }

    if (
      appPermissionValidator(
        APP_PERMISSION.SUBSCRIPTION_MANAGEMENT_VIEW_PAYMENT_METHODS
      )
    ) {
      const paymentMethodsRoute = {
        path: `${match.path}subscription/payment-methods`,
        exact: true,
        strict: true,
        component: PaymentMethodScreen,
      };
      routes.push(paymentMethodsRoute);
      baseRoutes.push(paymentMethodsRoute);
    }

    if (
      appPermissionValidator(
        APP_PERMISSION.SUBSCRIPTION_MANAGEMENT_VIEW_BILLING_ADDRESSES
      )
    ) {
      const billingAddressRoute = {
        path: `${match.path}subscription/billing-address`,
        exact: true,
        strict: true,
        component: BillingAddressScreen,
      };
      routes.push(billingAddressRoute);
      baseRoutes.push(billingAddressRoute);
    }

    if (
      appPermissionValidator(
        APP_PERMISSION.SUBSCRIPTION_MANAGEMENT_MANAGE_AUDIONAUT_PLAN
      )
    ) {
      const billingManagementRoute = {
        path: `${match.path}subscription/manage`,
        exact: true,
        strict: true,
        component: SubscriptionBillingPage,
      };
      routes.push(billingManagementRoute);
      if (onlyChatPro) {
        baseRoutes.push(billingManagementRoute);
      }
    }

    if (
      appPermissionValidator(
        APP_PERMISSION.SUBSCRIPTION_MANAGEMENT_MANAGE_AUDIONAUT_PLAN
      )
    ) {
      const userPlansRoute = {
        path: `${match.path}user-plans`,
        exact: true,
        strict: true,
        component: UserPlansPage,
      };
      routes.push(userPlansRoute);
      baseRoutes.push(userPlansRoute);
    }

    if (!onlyChatPro && appPermissionValidator(APP_PERMISSION.ADMIN)) {
      routes.push(
        {
          path: `${match.path}administration/user-management/users`,
          exact: true,
          strict: true,
          component: UserManagementPage,
        },
        {
          path: `${match.path}administration/user-management/groups`,
          exact: true,
          strict: true,
          component: GroupManagementPage,
        },
        {
          path: `${match.path}administration/user-management/roles`,
          exact: true,
          strict: true,
          component: RoleManagementPage,
        },
        {
          path: `${match.path}subscriptions/organizations-management/organizations`,
          exact: true,
          strict: true,
          component: OrganizationMagamentPage,
        }
      );

      if (
        !onlyChatPro &&
        appPermissionValidator(APP_PERMISSION.ADMIN_SET_VISIBILITY_LEVEL)
      ) {
        routes.push({
          path: `${match.path}administration/visibility`,
          exact: true,
          strict: true,
          component: VisibilityPage,
        });
      }

      if (isProductOwner) {
        routes.push(
          {
            path: `${match.path}subscriptions/plans-discounts`,
            exact: true,
            strict: true,
            component: PlansDiscountsPage,
          },
          {
            path: `${match.path}subscriptions/crud-topics`,
            exact: true,
            strict: true,
            component: CrudTopicsPage,
          },
          {
            path: `${match.path}subscriptions/chat-history`,
            exact: true,
            strict: true,
            component: ChatHistoryPage,
          }
        );
      }
    }

    routes.push({
      render: () => <>Not Found</>,
    });
  }

  const {
    data: currentSubscriptionInfoData,
    loading: currentSubscriptionInfoLoading,
    error: currentSubscriptionInfoError,
  } = useGetCurrentSubscriptionInfoQuery({
    fetchPolicy: "no-cache",
  });

  // const { data: platformsDataReceived } = useGetSourcesQuery({
  //   context: {
  //     clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
  //   },
  // });

  // const { data: platformVideosData } = useGetVideosQuery({
  //   context: {
  //     clientName: CLIENT_NAME.AUDIONAUTEXPLOREAPI,
  //   },
  // });

  const setNewSearchTerm = (term: any) => {
    setSearchTerm(term);
  };

  const clearSearch = (clear: boolean) => {
    if (clear) {
      setClearInputSearch(clear);
      setNewSearchTerm("");
    }
    setTimeout(() => {
      setClearInputSearch(false);
    }, 300);
  };

  const clickMainContainer = () => {
    setCollapseDrawer(true);
    setTimeout(() => {
      setCollapseDrawer(false);
    }, 300);
  };

  // React.useEffect(() => {
  //   if (platformsDataReceived) {
  //     const platformsRecived = platformsDataReceived.getSourcesInfo?.map(
  //       (platform: any) => {
  //         return platform.sourceName;
  //       }
  //     );
  //   }
  // }, [platformsDataReceived]);

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setUpdateStatus(undefined);
    },
    []
  );

  const successAboutAudionaut = React.useCallback(
    async (comment: string) => {
      setOpenAboutAudionaut(false);
      TagManager.dataLayer({
        dataLayer: {
          aboutCorporatus: comment,
        },
      });
      TagManager.dataLayer({
        dataLayer: {
          event: "hearAboutCorporatus",
        },
      });

      try {
        await updateUserData({
          variables: {
            id: userDataLocal.id,
            FindOutAudionaut: comment,
          },
        });

        setUpdateStatus({
          message: "Your feedback has been sent successfully",
          severity: "success",
        });
      } catch (err: any) {
        errorHandler(err);
      }
    },
    [userDataLocal, updateUserData, setOpenAboutAudionaut, errorHandler]
  );

  const clearStylesInjected1 = React.useCallback(
    (currentRef, currentIntervalId) => {
      // console.debug("=====> clearStylesInjected pageContentRef!!!");
      // console.debug("======> interval id: ", currentIntervalId);
      if (
        currentRef &&
        currentRef.current &&
        currentRef.current.attributes &&
        currentRef.current.attributes.getNamedItem("style") &&
        currentRef.current.attributes.getNamedItem("style")?.value
      ) {
        currentRef.current.attributes.removeNamedItem("style");
        // clearInterval(currentIntervalId);
        // setIntervalId(-1);
        // console.debug(
        //   "=====> pageContentRef cleaning the interval id 1",
        //   currentIntervalId
        // );
      }

      setRemovedStylesTimes((oldTime) => {
        const newTime = oldTime + 1;
        if (newTime > 4) {
          clearInterval(currentIntervalId);
          setIntervalId(-1);
          // console.debug(
          //   "=====> pageContentRef cleaning the interval id 2",
          //   currentIntervalId
          // );
        }
        return newTime;
      });
    },
    []
  );

  React.useEffect(() => {
    if (userDataLocal && userDataLocal.id) {
      // console.debug("=====> userDataLocal: ", userDataLocal);
      if (!userDataLocal.FindOutAudionaut) {
        setOpenAboutAudionaut(true);
      }
    }
  }, [userDataLocal, setOpenAboutAudionaut]);

  React.useEffect(() => {
    // console.debug("=====> This is mounted pageContentRef!!!");
    if (pageContentRef && pageContentRef.current) {
      const newIntervalId = setInterval(() => {
        clearStylesInjected1(pageContentRef, newIntervalId);
      }, 3000);
      setIntervalId(newIntervalId);
      // console.debug("====> newIntervalId", newIntervalId);

      const currentHandleGlobalEvents = HandleGlobalEvents.getInstance();

      currentHandleGlobalEvents.checkinginlineStyles$.subscribe((check) => {
        // console.debug(
        //   "=====> Re checking the injected styles: ",
        //   check,
        //   intervalId
        // );
        if (check) {
          if (intervalId === -1) {
            const newIntervalId2 = setInterval(() => {
              clearStylesInjected1(pageContentRef, newIntervalId2);
            }, 3000);
            setIntervalId(newIntervalId2);
          }
        }
      });
      currentHandleGlobalEvents.checkingStylesSummary$.subscribe((check) => {
        // console.debug(
        //   "=====> Re checking the injected styles: ",
        //   check,
        //   intervalId
        // );
        if (check) {
          if (intervalId === -1) {
            const newIntervalId2 = setInterval(() => {
              clearStylesInjected1(pageContentRef, newIntervalId2);
            }, 3000);
            setIntervalId(newIntervalId2);
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageContentRef]);

  React.useEffect(() => {
    if (
      (currentSubscriptionInfoData &&
        !currentSubscriptionInfoData.getCustomerSubscriptionInfo) ||
      (currentSubscriptionInfoData &&
        currentSubscriptionInfoData?.getCustomerSubscriptionInfo !== null &&
        !currentSubscriptionInfoData?.getCustomerSubscriptionInfo
          ?.hasActiveMeteredSubscription)
    ) {
      const script = document.createElement("script");
      script.id = "addsenseScript";
      script.src =
        "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-6458368253572204";
      script["data-adbreak-test"] = "on";
      script.crossOrigin = "anonymous";
      script.async = true;
      document.head.appendChild(script);
    }
    // else if (
    //   currentSubscriptionInfoData &&
    //   currentSubscriptionInfoData.getCustomerSubscriptionInfo !== null &&
    //   currentSubscriptionInfoData!.getCustomerSubscriptionInfo!
    //     .hasActiveMeteredSubscription
    // ) {
    //   if (document.getElementById("addsenseScript") !== null) {
    //     document.head.removeChild(document.getElementById("addsenseScript"));
    //   }
    // }
  }, [currentSubscriptionInfoData]);

  return (
    <SC.Page maxWidth={false} ref={pageRef} maxHeight={pageHeight}>
      <Navigation
        menuState={setMenuOpened}
        onChangePage={clearSearch}
        collapse={collapseDrawer}
        isChatPro={onlyChatPro}
      />
      <Box
        className={`pageContent ${localClasses.mainContainer}`}
        onClick={clickMainContainer}
      >
        {isMobileResolution() ? (
          <BannerNavigation
            isLogged
            hideSearchBar={
              !isProductOwner &&
              (!currentSubscriptionInfoData ||
                currentSubscriptionInfoData!.getCustomerSubscriptionInfo ===
                  null ||
                !currentSubscriptionInfoData!.getCustomerSubscriptionInfo
                  ?.hasActiveMeteredSubscription ||
                onlyChatPro)
            }
            doSearch={setNewSearchTerm}
            finishSearch={clearInputSearch}
          />
        ) : null}
        {false ? (
          <ProgressIndicator
            open={currentSubscriptionInfoLoading}
            backdropColor={
              !!planPreSelected && planPreSelected !== "chatFree" ? "#000" : ""
            }
          />
        ) : (
          <SC.PageContent
            maxWidth={false}
            ref={pageContentRef}
            maxHeight={
              pageContentHeight - (urlLocation[1] === "public-search" ? 25 : 0)
            }
          >
            {isProductOwner ||
            (!onlyChatPro &&
              currentSubscriptionInfoData &&
              currentSubscriptionInfoData?.getCustomerSubscriptionInfo !==
                null &&
              currentSubscriptionInfoData?.getCustomerSubscriptionInfo
                ?.hasActiveMeteredSubscription) ? (
              <>
                <Switch>
                  {routes.map((route) => (
                    <Route {...route} />
                  ))}
                </Switch>
                {searchTerm.length > 0 && (
                  <GeneralSearchPage
                    menuOpened={menuOpened}
                    searchTerm={searchTerm}
                    finishSearch={setLoading}
                    isModal
                  />
                )}
              </>
            ) : (
              <>
                <Switch>
                  {baseRoutes.map((route) => (
                    <Route {...route} />
                  ))}
                </Switch>
                <Redirect
                  to={
                    !planPreSelected || planPreSelected === "chatFree"
                      ? "/public-search"
                      : "/user-plans"
                  }
                />
              </>
            )}
          </SC.PageContent>
        )}
        <Footer
          hidden={urlLocation[1] !== "public-search" || isMobileResolution()}
        />
      </Box>
      <InfoTooltip
        open={false}
        content={
          <HearAboutAudionaut
            successHandler={successAboutAudionaut}
            cancelHandler={() => {
              setOpenAboutAudionaut(false);
            }}
          />
        }
        isModal
        customClasses={localClasses.aboutAudionaut}
      />
      <Snackbar
        open={!!updateStatus}
        autoHideDuration={3000}
        onClose={handleCloseSnack}
      >
        <Alert
          onClose={handleCloseSnack}
          severity={updateStatus?.severity}
          message={updateStatus?.message}
        />
      </Snackbar>
    </SC.Page>
  );
};

export default MainPage;
