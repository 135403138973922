/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React, { forwardRef } from "react";
import { useLocation, NavLink, NavLinkProps, Redirect } from "react-router-dom";
import { isMobile } from "react-device-detect";

import {
  Home as HomeIcon,
  PeopleAlt as PeopleAltIcon,
  Storefront as StoreFrontIcon,
  House as HouseIcon,
  Folder as FolderIcon,
  Search as SearchIcon,
} from "@material-ui/icons";
import SvgIcon, { SvgIconProps } from "@material-ui/core/SvgIcon";

import SmsIcon from "@material-ui/icons/Sms";
import PersonIcon from "@material-ui/icons/Person";

import {
  useGetCurrentUserPermissionsQuery,
  PermissionType,
  useGetCurrentSubscriptionInfoQuery,
} from "graphql/types-and-hooks";

import clsx from "clsx";
import {
  createStyles,
  makeStyles,
  useTheme,
  Theme,
} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import IconButton from "@material-ui/core/IconButton";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import {
  BottomNavigation,
  BottomNavigationAction,
  SwipeableDrawer,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import CropOriginalIcon from "@material-ui/icons/CropOriginal";

import Collapse from "@material-ui/core/Collapse";

// Components
import {
  APP_PERMISSION,
  isAppPermissionValid,
} from "components/app-permission-validator/app-permission-validator.component";
import BannerGraphic from "components/banner-graphic/banner-graphic.component";
import currentPeriodSubscriptionForUserOrganisation from "pages/user-plans/check_subscription";
import hasProductOwnerRole from "components/app-permission-validator/fetch_current_user_profile";

import ToggleDrawerIcon from "components/toggle-drawer-icon/toggle-drawer-icon.component";
import { isMobileResolution } from "commons/utils/device-info.util";
import ThemeSwitchComponent from "components/theme-switch/theme-switch.component";
import UserMenu from "components/user-menu/user-menu.component";

// Assets
import SC from "./navigation.styles";
import { ReactComponent as CollapseMenu } from "../../assets/icons/Collapsed menu.svg";
import { ReactComponent as AdminIcon } from "../../assets/icons/admin_panel_settings_black_24dp.svg";
import { ReactComponent as ExploreIcon } from "../../assets/icons/explore_icon.svg";
import { ReactComponent as AnalyzeIcon } from "../../assets/icons/analize_icon.svg";
import { ReactComponent as TargetIcon } from "../../assets/icons/target_icon.svg";
import { ReactComponent as manageSubscriptionIcon } from "../../assets/icons/manage_subscription_icon.svg";
import { ReactComponent as PlansIcon } from "../../assets/icons/plans_coupons_icon.svg";
import { ReactComponent as ChatBotIcon } from "../../assets/icons/chat.svg";
import { ReactComponent as KnowledgeIcon } from "../../assets/icons/knowledge.svg";

const drawerWidth = 264;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      width: "fit-content",
      position: "relative",
      zIndex: 1,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(["width", "margin"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    closeMenu: {
      fill: "#FFFFFF",
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
      whiteSpace: "nowrap",
      "& ul": {
        overflowY: "auto !important",
        overflowX: "hidden",
        flex: "1",
        "& .MuiListItem-root": {
          height: "80px",
        },
        "& svg": {
          fill: "#CCCCCC",
        },
        "& span": {
          color: "#CCCCCC",
        },
      },
    },
    drawerOpen: {
      width: drawerWidth,
      top: "auto !important",
      overflow: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      background: "#1C2624",
      color: "#CCCCCC",
      "& a": {
        "& .MuiListItemIcon-root": {
          "& svg": {
            margin: "auto",
            marginRight: "19px",
          },
          "& img": {
            margin: "auto",
            marginRight: "19px",
          },
        },
      },
      "& .MuiListItem-root": {
        "& .MuiListItemIcon-root": {
          "& img": {
            margin: "auto",
            marginRight: "19px",
          },
        },
      },
      "& .nestedItem": {
        "& .MuiListItemIcon-root": {
          "& svg": {
            margin: "auto",
          },
        },
      },
    },
    drawerClose: {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      top: "auto !important",
      overflowX: "hidden",
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9) + 1,
      },
      background: "#1C2624",
      color: "#CCCCCC",
      "& a": {
        "& .MuiListItemIcon-root": {
          minWidth: "unset",
          flex: "1",
          justifyContent: "center",
        },
        "& .MuiListItemText-root": {
          display: "none",
          flex: "unset",
        },
      },
      "& .nestedItem": {
        "& .MuiListItemIcon-root": {
          minWidth: "unset",
          flex: "1",
          justifyContent: "center",
          "& svg": {
            display: "block",
          },
        },
        "& .MuiListItemText-root": {
          display: "none",
        },
        "& svg": {
          display: "none",
        },
      },
    },
    toolbar: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    // nested: {
    //   paddingLeft: "45px",
    //   "& a": {
    //     height: "40px !important",
    //   },
    // },
  })
);

interface NavigationContentInterface {
  toggleDrawer: () => void;
  onChangePage: (arg1) => void;
  handleItemClick: (arg1) => void;
  currentItemSelected: string;
  menuOpened: boolean;
  isChatPro?: boolean;
}

const NavigationContent: React.FC<NavigationContentInterface> = ({
  toggleDrawer,
  onChangePage,
  handleItemClick,
  currentItemSelected,
  menuOpened,
  isChatPro = false,
}) => {
  const isProductOwner = hasProductOwnerRole();

  const location = useLocation();
  const urlLocation = location.pathname.split("/");

  const { data, error } = useGetCurrentUserPermissionsQuery({
    variables: {
      type: PermissionType.Application,
    },
  });

  const {
    data: currentSubscriptionInfoData,
    loading: currentSubscriptionInfoLoading,
    error: currentSubscriptionInfoError,
  } = useGetCurrentSubscriptionInfoQuery();

  const userHasActiveSubscription = React.useMemo(
    () =>
      currentSubscriptionInfoData &&
      currentSubscriptionInfoData?.getCustomerSubscriptionInfo !== null &&
      currentSubscriptionInfoData?.getCustomerSubscriptionInfo
        ?.hasActiveMeteredSubscription,
    [currentSubscriptionInfoData]
  );

  return (
    <SC.NavigationList>
      {!isChatPro &&
      data &&
      isAppPermissionValid(
        data.GetCurrentUserPermissions,
        APP_PERMISSION.HOME_VIEW
      ) ? (
        <ListItem
          button
          key="Home"
          component={forwardRef((props: NavLinkProps, ref: any) => (
            <NavLink exact {...props} innerRef={ref} />
          ))}
          to={userHasActiveSubscription ? "/summary-statistics" : "/user-plans"}
          className={`${
            !urlLocation[1] || urlLocation[1] === "summary-statistics"
              ? "active"
              : ""
          }`}
          onClick={onChangePage}
        >
          <ListItemIcon>
            <Tooltip title="Home" aria-label="home-page">
              <HomeIcon />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Home" />
        </ListItem>
      ) : null}
      {data &&
      isAppPermissionValid(
        data.GetCurrentUserPermissions,
        APP_PERMISSION.HOME_VIEW
      ) ? (
        <ListItem
          button
          key="Public Search"
          component={forwardRef((props: NavLinkProps, ref: any) => (
            <NavLink exact {...props} innerRef={ref} />
          ))}
          to="/public-search"
          onClick={onChangePage}
          className={`${urlLocation[1] === "public-search" ? "active" : ""}`}
        >
          <ListItemIcon>
            <Tooltip title="Public Search" aria-label="public-search-page">
              <ChatBotIcon height="24px" width="24px" />
            </Tooltip>
          </ListItemIcon>
          <ListItemText primary="Public Search" />
        </ListItem>
      ) : null}

      {!isProductOwner &&
      data &&
      isAppPermissionValid(
        data.GetCurrentUserPermissions,
        APP_PERMISSION.SUBSCRIPTION_MANAGEMENT_VIEW
      ) ? (
        <>
          <ListItem
            button
            onClick={() => {
              if (!menuOpened) {
                toggleDrawer();
              }
              handleItemClick("subscription");
            }}
            className={`nestedItem${
              urlLocation[1] === "subscription" ? " active" : ""
            }`}
          >
            <ListItemIcon>
              <Tooltip title="Subscription" aria-label="subscriptions">
                <SvgIcon component={PlansIcon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Subscription" />
            {currentItemSelected === "subscription" ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>
          <Collapse
            in={currentItemSelected === "subscription"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              {data &&
              isAppPermissionValid(
                data.GetCurrentUserPermissions,
                APP_PERMISSION.SUBSCRIPTION_MANAGEMENT_VIEW_BILLING_ADDRESSES
              ) ? (
                <ListItem
                  button
                  component={forwardRef((props: NavLinkProps, ref: any) => (
                    <NavLink exact {...props} innerRef={ref} />
                  ))}
                  className={`subItem${
                    urlLocation[2] === "billing-address" ? " active" : ""
                  }`}
                  to="/subscription/billing-address"
                  onClick={onChangePage}
                >
                  <ListItemText primary="Billing Address" />
                </ListItem>
              ) : null}
              {data &&
              isAppPermissionValid(
                data.GetCurrentUserPermissions,
                APP_PERMISSION.SUBSCRIPTION_MANAGEMENT_VIEW_PAYMENT_METHODS
              ) ? (
                <ListItem
                  button
                  component={forwardRef((props: NavLinkProps, ref: any) => (
                    <NavLink exact {...props} innerRef={ref} />
                  ))}
                  className={`subItem${
                    urlLocation[2] === "payment-methods" ? " active" : ""
                  }`}
                  to="/subscription/payment-methods"
                  onClick={onChangePage}
                >
                  <ListItemText primary="Payment Method" />
                </ListItem>
              ) : null}
              {userHasActiveSubscription &&
              data &&
              isAppPermissionValid(
                data.GetCurrentUserPermissions,
                APP_PERMISSION.SUBSCRIPTION_MANAGEMENT_MANAGE_AUDIONAUT_PLAN
              ) ? (
                <ListItem
                  button
                  component={forwardRef((props: NavLinkProps, ref: any) => (
                    <NavLink exact {...props} innerRef={ref} />
                  ))}
                  className={`subItem${
                    urlLocation[1] === "subscription" &&
                    urlLocation[2] === "manage"
                      ? " active"
                      : ""
                  }`}
                  to="/subscription/manage"
                  onClick={onChangePage}
                >
                  <ListItemText primary="Manage Subscription" />
                </ListItem>
              ) : null}
            </List>
          </Collapse>
        </>
      ) : null}
      {/* {!isChatPro &&
      data &&
      userHasActiveSubscription &&
      isAppPermissionValid(
        data.GetCurrentUserPermissions,
        APP_PERMISSION.ADMIN
      ) ? (
        <>
          <ListItem
            button
            onClick={() => {
              if (!menuOpened) {
                toggleDrawer();
              }
              handleItemClick("admin");
            }}
            className={`nestedItem${
              urlLocation[1] === "administration" ? " active" : ""
            }`}
            style={{ background: "none" }}
          >
            <ListItemIcon>
              <Tooltip title="Administration" aria-label="administration">
                <SvgIcon component={AdminIcon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText primary="Administration" />
            {currentItemSelected === "admin" ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse
            in={currentItemSelected === "admin"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                component={forwardRef((props: NavLinkProps, ref: any) => (
                  <NavLink exact {...props} innerRef={ref} />
                ))}
                to={
                  userHasActiveSubscription
                    ? "/administration/user-management/users"
                    : "/user-plans"
                }
                className={`subItem${
                  urlLocation[3] === "users" ? " active" : ""
                }`}
                onClick={onChangePage}
              >
                <ListItemText primary="Users" />
              </ListItem>
              <ListItem
                button
                component={forwardRef((props: NavLinkProps, ref: any) => (
                  <NavLink exact {...props} innerRef={ref} />
                ))}
                to={
                  userHasActiveSubscription
                    ? "/administration/user-management/groups"
                    : "/user-plans"
                }
                className={`subItem${
                  urlLocation[3] === "groups" ? " active" : ""
                }`}
                onClick={onChangePage}
              >
                <ListItemText primary="Groups" />
              </ListItem>
              <ListItem
                button
                component={forwardRef((props: NavLinkProps, ref: any) => (
                  <NavLink exact {...props} innerRef={ref} />
                ))}
                to={
                  userHasActiveSubscription
                    ? "/administration/user-management/roles"
                    : "/user-plans"
                }
                className={`subItem${
                  urlLocation[3] === "roles" ? " active" : ""
                }`}
                onClick={onChangePage}
              >
                <ListItemText primary="Roles" />
              </ListItem>
            </List>
          </Collapse>
        </>
      ) : null} */}
      {isProductOwner ? (
        <>
          <ListItem
            button
            onClick={() => {
              if (!menuOpened) {
                toggleDrawer();
              }
              handleItemClick("productOwner");
            }}
            className={`nestedItem${
              urlLocation[1] === "subscriptions" ? " active" : ""
            }`}
          >
            <ListItemIcon>
              <Tooltip title="Product Owner Menu" aria-label="product-owner">
                <SvgIcon component={manageSubscriptionIcon} />
              </Tooltip>
            </ListItemIcon>
            <ListItemText
              primary="Product Owner Menu"
              primaryTypographyProps={{ style: { whiteSpace: "normal" } }}
            />
            {currentItemSelected === "productOwner" ? (
              <ExpandLess />
            ) : (
              <ExpandMore />
            )}
          </ListItem>
          <Collapse
            in={currentItemSelected === "productOwner"}
            timeout="auto"
            unmountOnExit
          >
            <List component="div" disablePadding>
              <ListItem
                button
                component={forwardRef((props: NavLinkProps, ref: any) => (
                  <NavLink exact {...props} innerRef={ref} />
                ))}
                className={`subItem${
                  urlLocation[2] === "plans-discounts" ? " active" : ""
                }`}
                to="/subscriptions/plans-discounts"
                onClick={onChangePage}
              >
                <ListItemText primary="Plans and Discounts" />
              </ListItem>
              <ListItem
                button
                component={forwardRef((props: NavLinkProps, ref: any) => (
                  <NavLink exact {...props} innerRef={ref} />
                ))}
                className={`subItem${
                  urlLocation[3] === "crud-topics" ? " active" : ""
                }`}
                to="/subscriptions/crud-topics"
                onClick={onChangePage}
              >
                <ListItemText primary="Topics" />
              </ListItem>
              <ListItem
                button
                component={forwardRef((props: NavLinkProps, ref: any) => (
                  <NavLink exact {...props} innerRef={ref} />
                ))}
                to="/subscriptions/organizations-management/organizations"
                className={`subItem${
                  urlLocation[4] === "organizations" ? " active" : ""
                }`}
                onClick={onChangePage}
              >
                <ListItemText primary="Organizations" />
              </ListItem>
              <ListItem
                button
                component={forwardRef((props: NavLinkProps, ref: any) => (
                  <NavLink exact {...props} innerRef={ref} />
                ))}
                className={`subItem${
                  urlLocation[4] === "chat-history" ? " active" : ""
                }`}
                to="/subscriptions/chat-history"
                onClick={onChangePage}
              >
                <ListItemText primary="Chat History" />
              </ListItem>
            </List>
          </Collapse>
        </>
      ) : null}
    </SC.NavigationList>
  );
};

interface NavigationProps {
  menuState: (arg1: boolean) => void;
  onChangePage: (arg1) => void;
  collapse?: boolean;
  isChatPro?: boolean;
}

const Navigation: React.FC<NavigationProps> = ({
  menuState,
  onChangePage,
  collapse = false,
  isChatPro = false,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openAdmin, setOpenAdminItem] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState(false);
  const [openExplore, setOpenExplore] = React.useState(false);
  const [openAnalyze, setOpenAnalyze] = React.useState(false);
  const [openTarget, setOpenTarget] = React.useState(false);
  const [itemSelected, setItemSelected] = React.useState("");
  const [openSubscriptionMenu, setOpenSubscriptionMenu] = React.useState(false);
  const [openProductOwnerMenu, setOpenProductOwnerMenu] = React.useState(false);

  const [redirectToProfile, setRedirectToProfile] = React.useState(false);
  const [redirectToChat, setRedirectToChat] = React.useState(false);

  const [value, setValue] = React.useState(0);

  const location = useLocation();
  const urlLocation = location.pathname.split("/");

  const goHome = () => {
    // setClearInput(true);
    // history.push("summary-statistics");
    // setTimeout(() => {
    //   setClearInput(false);
    // }, 300);
  };

  const subMenuHandleClick = React.useCallback(() => {
    setOpenSubMenu(!openSubMenu);
  }, [openSubMenu, setOpenSubMenu]);

  const adminItemHandleClick = React.useCallback(() => {
    setOpenAdminItem(!openAdmin);
    if (openSubMenu) subMenuHandleClick();
  }, [openAdmin, openSubMenu, setOpenAdminItem, subMenuHandleClick]);

  const pageHandleClick = React.useCallback(
    (page: string) => {
      switch (page) {
        case "explore":
          setOpenExplore(!openExplore);
          setItemSelected(itemSelected === "explore" ? "" : "explore");
          break;
        case "analyze":
          setOpenAnalyze(!openAnalyze);
          setItemSelected(itemSelected === "analyze" ? "" : "analyze");
          break;
        case "target":
          setOpenTarget(!openTarget);
          setItemSelected(itemSelected === "target" ? "" : "target");
          break;
        case "subscription":
          setOpenSubscriptionMenu(!openSubscriptionMenu);
          setItemSelected(
            itemSelected === "subscription" ? "" : "subscription"
          );
          break;
        case "admin":
          adminItemHandleClick();
          setItemSelected(itemSelected === "admin" ? "" : "admin");
          break;
        case "productOwner":
          setOpenProductOwnerMenu(!openProductOwnerMenu);
          setItemSelected(
            itemSelected === "productOwner" ? "" : "productOwner"
          );
          break;
        default:
          break;
      }
    },
    [
      itemSelected,
      openExplore,
      setOpenExplore,
      openAnalyze,
      setOpenAnalyze,
      openTarget,
      setOpenTarget,
      openSubscriptionMenu,
      setOpenSubscriptionMenu,
      openProductOwnerMenu,
      setOpenProductOwnerMenu,
      adminItemHandleClick,
    ]
  );

  const handleDrawerOpen = React.useCallback(() => {
    setOpen(true);
    menuState(open);
  }, [open, setOpen, menuState]);

  const handleDrawerClose = React.useCallback(() => {
    setOpen(false);
    menuState(open);

    if (openAdmin) pageHandleClick("admin");
    if (openExplore) pageHandleClick("explore");
    if (openAnalyze) pageHandleClick("analyze");
    if (openTarget) pageHandleClick("target");
    if (openSubscriptionMenu) pageHandleClick("subscription");
    if (openProductOwnerMenu) pageHandleClick("prodcutOwner");
    if (openSubMenu) subMenuHandleClick();
  }, [
    setOpen,
    open,
    menuState,
    openAdmin,
    openSubMenu,
    openExplore,
    openAnalyze,
    openTarget,
    openSubscriptionMenu,
    openProductOwnerMenu,
    subMenuHandleClick,
    pageHandleClick,
  ]);

  const HandleOnchangePage = React.useCallback(
    (event) => {
      onChangePage(event);
      if (isMobileResolution()) {
        setOpen(false);
        menuState(open);
      }
    },
    [onChangePage, setOpen, menuState, open]
  );

  // const currentPeriodSubscription =
  //   currentPeriodSubscriptionForUserOrganisation();

  React.useEffect(() => {
    if (collapse) {
      handleDrawerClose();
    }
  }, [collapse, handleDrawerClose]);

  // React.useEffect(() => {
  //   console.debug("=====> current theme breakpoint: ", theme.breakpoints.);
  // }, [theme.breakpoints]);

  return (
    <>
      {isMobileResolution() ? (
        // <>
        //   <SC.MobileToggleDrawerIcon
        //     opened={open}
        //     openHandler={handleDrawerOpen}
        //     closeHandler={handleDrawerClose}
        //     isMobile
        //   />
        //   <ThemeSwitchComponent minimized />
        //   <UserMenu collapsed={!open} />
        // </>

        <SC.MobileNavigation
          hidden={
            urlLocation[1] !== "user-menu" && urlLocation[1] !== "public-search"
          }
          value={value}
          onChange={(event, newValue) => {
            setValue(newValue);
          }}
          showLabels
          // className={classes.root}
        >
          <BottomNavigationAction
            label="Chat"
            icon={<SmsIcon />}
            LinkComponent={forwardRef((props: NavLinkProps, ref: any) => (
              <NavLink exact {...props} innerRef={ref} to="/public-search" />
            ))}
            onClick={() => {
              setRedirectToChat(true);
              setRedirectToProfile(false);
            }}
            className="navigation-item"
          />
          {/* <BottomNavigationAction
            label="Knowledge"
            icon={<SvgIcon component={KnowledgeIcon} />}
            className="navigation-item"
          /> */}
          <BottomNavigationAction
            label="Account"
            icon={<PersonIcon />}
            onClick={() => {
              setRedirectToProfile(true);
              setRedirectToChat(false);
            }}
            className="navigation-item"
          />
        </SC.MobileNavigation>
      ) : null}
      <Drawer
        hidden={isMobileResolution()}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <BannerGraphic goHome={goHome} open={open} />
        <NavigationContent
          toggleDrawer={handleDrawerOpen}
          onChangePage={HandleOnchangePage}
          handleItemClick={pageHandleClick}
          currentItemSelected={itemSelected}
          menuOpened={open}
          isChatPro={isChatPro}
        />
        <UserMenu collapsed={!open} />
        <ThemeSwitchComponent minimized={!open} />
        {!isMobileResolution() ? (
          <ToggleDrawerIcon
            opened={open}
            openHandler={handleDrawerOpen}
            closeHandler={handleDrawerClose}
            isMobile={false}
          />
        ) : null}
      </Drawer>

      <SC.MobileLateralMenu
        hidden={!isMobileResolution()}
        open={open}
        onOpen={handleDrawerOpen}
        onClose={handleDrawerClose}
      >
        <BannerGraphic goHome={goHome} open={false} />
        <NavigationContent
          toggleDrawer={handleDrawerOpen}
          onChangePage={HandleOnchangePage}
          handleItemClick={pageHandleClick}
          currentItemSelected={itemSelected}
          menuOpened={open}
          isChatPro={isChatPro}
        />
      </SC.MobileLateralMenu>
      {redirectToProfile ? <Redirect to="/user-menu" /> : null}
      {redirectToChat ? <Redirect to="/public-search" /> : null}
    </>
  );
};

export default Navigation;
