/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable react/jsx-props-no-spreading */
// Dependencies
import React from "react";
import { Box, CircularProgress, TableCellProps } from "@material-ui/core";
import { useWindowHeight } from "@react-hook/window-size";
import {
  useTable,
  TableOptions,
  usePagination,
  Row,
  Column,
  useSortBy,
  useExpanded,
  useFlexLayout,
  useResizeColumns,
  useColumnOrder,
  useRowSelect,
  useFilters,
  useAsyncDebounce,
} from "react-table";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

// Components
import TableToolbar from "components/table-toolbar/table-toolbar.component";
import { TableToolbarAction } from "components/table-toolbar-actions/table-toolbar-actions.component";
import LeftPaneContainer from "components/left-pane/left-pane.component";
import TransferList from "components/transfer-list/transfer-list.component";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";

import { isMobileResolution } from "commons/utils/device-info.util";

// Assets
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

import SC from "./table.styles";

// Table Actions
import handleExportToCSV from "./table.actions";

const EXPANDER_CELL_ID = "expander";
const VISIBILITY_CELL_ID = "visibility";

export const ROWS_PER_PAGE_OPTIONS_DEFAULT = [5, 10, 25];
export const INITIAL_ROWS_PER_PAGE = {
  DEFAULT: 5,
  REPORTS: 25,
};

const expanderCell = {
  Header: () => null,
  id: EXPANDER_CELL_ID,
  disableSortBy: true,
  align: "left",
  Cell: ({ row }: { row: Row }) => (
    <SC.ExpanderIconButton
      // @ts-ignore
      {...row.getToggleRowExpandedProps()}
    >
      {
        // @ts-ignore
        row.isExpanded ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />
      }
    </SC.ExpanderIconButton>
  ),
  disableResizing: true,
  width: 55,
  minWidth: 50,
  maxWidth: 50,
};

const visibilityCell = {
  Header: () => null,
  id: VISIBILITY_CELL_ID,
  disableSortBy: true,
  align: "left",
  Cell: ({ row }: { row: Row }) => (
    <SC.VisibilityIcon
      color="primary"
      // @ts-ignore
      {...row.getToggleRowSelectedProps()}
    />
  ),
  disableResizing: true,
  width: 50,
  minWidth: 50,
  maxWidth: 50,
};

export type TableColumn<D extends Record<string, unknown>> = Column<D> & {
  align?: TableCellProps["align"];
  sort?: boolean;
  hiddenColumn?: boolean;
};

export type TableFetchDataFunctionParams = {
  pageIndex: number;
  pageSize: number;
  sortBy: { id: string; desc: boolean }[];
};

export type TableFetchDataFunction = (
  params: TableFetchDataFunctionParams
) => void;

export type TablePaginationControlled = {
  /** It allows to pass a function to retrieve data from somewhere such as an API. */
  fetchData: TableFetchDataFunction;
  loading: boolean;
  totalRowsCount: number;
};

export interface TableProps<D extends Record<string, unknown>>
  extends TableOptions<D> {
  columns: TableColumn<D>[];
  footer?: React.ReactNode;
  hiddenFooter?: boolean;
  hiddenTitle?: boolean;
  setCustomRowClass?: string;
  title?: string | JSX.Element;
  isPaginationHidden?: boolean;
  buttonText?: string;
  /** It will be used to persist the User Settings table state in the local storage. and should be unique from other table persistanceIds */
  persistenceId?: string;
  rowsPerPageOptions?: number[];
  renderExpandedRowSubComponent?: (row: Row<D>) => JSX.Element;
  renderVisibilityRowComponent?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRowSelect?: (values: any) => void;
  onSaveTableChanges?: () => void;
  leftPanel?: JSX.Element;
  topPanel?: JSX.Element;
  headerPanel?: JSX.Element;
  actionsOnLeft?: TableToolbarAction[];
  actionsOnRight?: TableToolbarAction[];
  onAction: (action: TableToolbarAction) => void;
  paginationControlled?: TablePaginationControlled;
  /**  Is used to specify that the table will have sticky header */
  stickyHeader?: boolean;
  /** Specify the height of the table */
  maxHeight?: number;
  /** Is used to specify the initial rows per page */
  initialRowsPerPage?: number;
  /** Allows to reset to zero the page index of the table pagination
   * by toggling the value of this property. */
  pageIndexResetSignal?: boolean;
  loading?: boolean;
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const Table = <D extends Record<string, unknown>>(props: TableProps<D>) => {
  const {
    hiddenTitle,
    title,
    hiddenFooter,
    setCustomRowClass = "",
    buttonText,
    onSaveTableChanges,
    persistenceId,
    rowsPerPageOptions = ROWS_PER_PAGE_OPTIONS_DEFAULT,
    renderExpandedRowSubComponent,
    renderVisibilityRowComponent,
    onRowSelect,
    leftPanel,
    topPanel,
    headerPanel,
    actionsOnLeft,
    actionsOnRight,
    isPaginationHidden,
    onAction,
    columns,
    data,
    paginationControlled,
    stickyHeader = false,
    maxHeight,
    initialRowsPerPage = INITIAL_ROWS_PER_PAGE.DEFAULT,
    pageIndexResetSignal,
    loading,
    ...tableProps
  } = props;

  const classes = SC.useTableStyles();
  const [showFilter, setShowFilter] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const tableId = `table-${persistenceId}`;

  const newColumns = React.useMemo(
    () =>
      // eslint-disable-next-line no-nested-ternary
      renderExpandedRowSubComponent
        ? ([expanderCell, ...columns] as TableColumn<D>[])
        : renderVisibilityRowComponent
        ? ([visibilityCell, ...columns] as TableColumn<D>[])
        : columns,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [columns, renderExpandedRowSubComponent, renderVisibilityRowComponent]
  );

  const defaultColumn = React.useMemo(
    () => ({
      minWidth: 100,
    }),
    []
  );

  const defaultSortColumn = React.useMemo(() => {
    const sortColumns: { id: string; desc: boolean }[] = [];
    newColumns.forEach((col) => {
      if (col.sort) {
        // @ts-ignore
        sortColumns.push({ id: col.accessor, desc: true });
      }
    });
    return sortColumns;
  }, [newColumns]);

  const storedState = React.useMemo(() => {
    if (persistenceId && typeof Storage !== "undefined") {
      const persistentState = localStorage.getItem(tableId);
      if (persistentState) {
        return JSON.parse(persistentState);
      }
    }
    return null;
  }, [persistenceId, tableId]);

  // No right TS types defined. The next version v8 will be being built
  // with TS natively. https://github.com/tannerlinsley/react-table/issues/3064
  const {
    getTableProps,
    headerGroups,
    footerGroups,
    getTableBodyProps,
    prepareRow,
    // @ts-ignore
    page,
    // @ts-ignore
    gotoPage,
    // @ts-ignore
    toggleSortBy,
    // @ts-ignore
    setPageSize,
    // @ts-ignore
    state: { pageIndex, pageSize, sortBy },
    allColumns,
    // @ts-ignore
    setColumnOrder,
    setHiddenColumns,
    state,
    visibleColumns,
  } = useTable(
    {
      columns: newColumns,
      data,
      defaultColumn,
      // @ts-ignore
      manualPagination: !!paginationControlled,
      manualSortBy: !!paginationControlled,
      disableMultiSort: true,
      disableSortRemove: true,
      pageCount,
      ...tableProps,
      initialState: {
        // @ts-ignore
        pageSize: initialRowsPerPage,
        sortBy: defaultSortColumn,
        ...storedState,
      },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useFlexLayout,
    useResizeColumns,
    useColumnOrder
  );

  const handleronPageChange = (newPage: number) => {
    gotoPage(newPage);
  };

  const handlerOnChangeSortOrder = (columnz: Column) => {
    // @ts-ignore
    toggleSortBy(columnz.id, !columnz.isSortedDesc, false);
  };

  const handlerOnChangeRowsPerPage = (newRowsPerPage: number) => {
    setPageSize(newRowsPerPage);
  };

  const onActionExtract = (action: TableToolbarAction) => {
    switch (action) {
      case "filter-results":
        setShowFilter(!showFilter);
        break;
      case "hide/show-columns":
        setModal(!modal);
        break;
      case "export-to-excel-sheet/csv":
        handleExportToCSV(data, columns);
        break;
      default:
        onAction(action);
    }
  };

  React.useEffect(() => {
    if (!storedState) {
      const hiddenColumns: string[] = [];
      newColumns.forEach((col) => {
        if (col.hiddenColumn) {
          // @ts-ignore
          hiddenColumns.push(col.accessor);
        }
      });
      setHiddenColumns(hiddenColumns);
    }
  }, [newColumns, setHiddenColumns, storedState]);

  React.useEffect(() => {
    if (persistenceId && typeof Storage !== "undefined") {
      const stateToPersist = paginationControlled
        ? _.omit(state, ["pageIndex", "pageSize", "sortBy"])
        : state;

      localStorage.setItem(tableId, JSON.stringify(stateToPersist));
    }
  }, [state, persistenceId, tableId, paginationControlled]);

  const totalRowsCount = paginationControlled
    ? paginationControlled.totalRowsCount
    : data.length;

  React.useEffect(() => {
    setPageCount(Math.ceil(totalRowsCount / pageSize));
  }, [pageSize, totalRowsCount]);

  const fetchData = useAsyncDebounce(
    paginationControlled?.fetchData ?? (() => null),
    100
  );
  React.useEffect(() => {
    fetchData?.({ pageIndex, pageSize, sortBy });
  }, [fetchData, pageIndex, pageSize, sortBy]);

  React.useEffect(() => {
    gotoPage(0);
  }, [gotoPage, pageIndexResetSignal]);

  // Variables used to calculate table container height for sticky header
  const mainContainerRef = React.useRef<HTMLDivElement>(null);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const mainContainerPosition =
    mainContainerRef.current?.getBoundingClientRect();
  const tableContainerPosition =
    tableContainerRef.current?.getBoundingClientRect();
  const mainContainerPositionY = mainContainerPosition?.y ?? 0;
  const tableContainerPositionY = tableContainerPosition?.y ?? 0;
  const windowHeight = useWindowHeight();
  const containerHeight = windowHeight - mainContainerPositionY;
  const tableHeight = windowHeight - tableContainerPositionY;

  return (
    <SC.Container
      maxWidth={false}
      className={classes.root}
      ref={mainContainerRef}
      maxHeight={maxHeight}
    >
      {leftPanel && (
        <SC.LeftPaneBox
          pane={showFilter}
          maxHeight={maxHeight ?? (stickyHeader ? containerHeight : undefined)}
        >
          <LeftPaneContainer>{leftPanel}</LeftPaneContainer>
        </SC.LeftPaneBox>
      )}

      <SC.RightPaneBox pane={leftPanel && showFilter}>
        <SC.TableContainer>
          {headerPanel && <SC.TopPaneBox>{headerPanel}</SC.TopPaneBox>}

          <Box
            display="flex"
            flexDirection="column"
            className="topPanelContainer"
          >
            <TableToolbar
              hiddenTitle={hiddenTitle}
              title={title}
              toolbarButtonText={buttonText}
              onSaveButton={onSaveTableChanges}
              rowsCount={totalRowsCount}
              page={pageIndex}
              rowsPerPage={pageSize}
              isPaginationHidden={isPaginationHidden}
              rowsPerPageOptions={rowsPerPageOptions}
              onPageChange={handleronPageChange}
              onChangeRowsPerPage={handlerOnChangeRowsPerPage}
              actionsOnLeft={actionsOnLeft}
              actionsOnRight={actionsOnRight}
              onAction={onActionExtract}
            />

            {topPanel && <SC.TopPaneBox>{topPanel}</SC.TopPaneBox>}
          </Box>

          <SC.Container
            className="table-container"
            maxWidth={false}
            ref={tableContainerRef}
            maxHeight={
              // eslint-disable-next-line no-nested-ternary
              stickyHeader
                ? maxHeight
                  ? maxHeight - mainContainerPositionY - tableContainerPositionY
                  : tableHeight
                : maxHeight
            }
          >
            <SC.Table {...getTableProps()} stickyHeader={stickyHeader}>
              <SC.TableHead>
                {headerGroups.map((headerGroup) => (
                  <SC.TableRow {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => {
                      const tableColumn = column as TableColumn<D>;

                      return isMobileResolution() &&
                        (column.id === "firstName" ||
                          column.id === "expander") ? (
                        <SC.TableCellSticky
                          /* @ts-ignore */
                          state={state.columnResizing}
                          cellid={column.id}
                          align={tableColumn.align}
                          {...column.getHeaderProps(
                            // @ts-ignore
                            column.getResizerProps
                          )}
                          className={
                            column.id === EXPANDER_CELL_ID
                              ? "firstCell"
                              : "lastCell"
                          }
                        >
                          <SC.TableCellHeader
                            align={tableColumn.align}
                            color="primary"
                          >
                            <span>
                              <span
                                {...column.getHeaderProps(
                                  // @ts-ignore
                                  column.getSortByToggleProps
                                )}
                              >
                                {column.render("Header")}
                              </span>
                              {
                                // @ts-ignore
                                column.isSorted && (
                                  <SC.TableSortLabel
                                    IconComponent={ArrowDropDownIcon}
                                    active
                                    direction={
                                      // @ts-ignore
                                      column.isSortedDesc ? "desc" : "asc"
                                    }
                                    onClick={() =>
                                      handlerOnChangeSortOrder(column)
                                    }
                                  />
                                )
                              }
                            </span>
                            {
                              // @ts-ignore
                              column.canResize && (
                                <SC.Resizer
                                  {...column.getHeaderProps(
                                    // @ts-ignore
                                    column.getResizerProps
                                  )}
                                />
                              )
                            }
                          </SC.TableCellHeader>
                        </SC.TableCellSticky>
                      ) : (
                        <SC.TableCell
                          /* @ts-ignore */
                          state={state.columnResizing}
                          cellid={column.id}
                          align={tableColumn.align}
                          {...column.getHeaderProps(
                            // @ts-ignore
                            column.getResizerProps
                          )}
                        >
                          <SC.TableCellHeader align={tableColumn.align}>
                            <span>
                              <Box
                                component="span"
                                {...column.getHeaderProps(
                                  // @ts-ignore
                                  column.getSortByToggleProps
                                )}
                                color="primary"
                              >
                                {column.render("Header")}
                              </Box>
                              {
                                // @ts-ignore
                                column.isSorted && (
                                  <SC.TableSortLabel
                                    IconComponent={ArrowDropDownIcon}
                                    active
                                    direction={
                                      // @ts-ignore
                                      column.isSortedDesc ? "desc" : "asc"
                                    }
                                    onClick={() =>
                                      handlerOnChangeSortOrder(column)
                                    }
                                  />
                                )
                              }
                            </span>
                            {
                              // @ts-ignore
                              column.canResize && (
                                <SC.Resizer
                                  {...column.getHeaderProps(
                                    // @ts-ignore
                                    column.getResizerProps
                                  )}
                                />
                              )
                            }
                          </SC.TableCellHeader>
                        </SC.TableCell>
                      );
                    })}
                  </SC.TableRow>
                ))}
              </SC.TableHead>
              <SC.TableBody {...getTableBodyProps()}>
                {page.map((row: Row<D>) => {
                  prepareRow(row);
                  let customRowClass = "";

                  if (setCustomRowClass) {
                    customRowClass = row.values[setCustomRowClass];
                  }

                  return (
                    <React.Fragment key={uuidv4()}>
                      <SC.TableRow
                        {...row.getRowProps()}
                        className={customRowClass}
                      >
                        {row.cells.map((cell) => {
                          const tableColumn = cell.column as TableColumn<D>;

                          return isMobileResolution() &&
                            (cell.column.id === "firstName" ||
                              cell.column.id === "expander") ? (
                            <SC.TableCellSticky
                              {...cell.getCellProps()}
                              /* @ts-ignore */
                              state={state.columnResizing}
                              cellid={cell.column.id}
                              align={tableColumn.align}
                              padding={
                                cell.column.id === EXPANDER_CELL_ID
                                  ? "none"
                                  : "normal"
                              }
                              className={
                                cell.column.id === EXPANDER_CELL_ID
                                  ? "collapsable-icon firstCell"
                                  : "lastCell"
                              }
                            >
                              {cell.render("Cell")}
                            </SC.TableCellSticky>
                          ) : (
                            <SC.TableCell
                              {...cell.getCellProps()}
                              /* @ts-ignore */
                              state={state.columnResizing}
                              cellid={cell.column.id}
                              align={tableColumn.align}
                              padding={
                                cell.column.id === EXPANDER_CELL_ID
                                  ? "none"
                                  : "normal"
                              }
                              className={
                                cell.column.id === EXPANDER_CELL_ID
                                  ? "collapsable-icon"
                                  : ""
                              }
                            >
                              {cell.render("Cell")}
                            </SC.TableCell>
                          );
                        })}
                      </SC.TableRow>
                      {
                        // @ts-ignore
                        renderExpandedRowSubComponent && row.isExpanded && (
                          <SC.TableRow>
                            <SC.TableCell
                              colSpan={newColumns.length}
                              style={{ padding: "0px" }}
                            >
                              {renderExpandedRowSubComponent(row)}
                            </SC.TableCell>
                          </SC.TableRow>
                        )
                      }
                    </React.Fragment>
                  );
                })}
                <React.Fragment key={uuidv4()}>
                  <SC.NoResults
                    show={page.length === 0 && !loading}
                    title="No results"
                    description="We don't have any information registered."
                  />
                  <Box
                    hidden={!loading}
                    style={{
                      backgroundColor: "rgba(0,0,0,.1)",
                      display: "flex",
                      width: "100%",
                      minHeight: "100px",
                    }}
                  >
                    <CircularProgress style={{ margin: "auto" }} />
                  </Box>
                </React.Fragment>
              </SC.TableBody>

              <SC.TableFooter hidden={hiddenFooter}>
                {footerGroups.map((group) => (
                  <SC.TableRow {...group.getFooterGroupProps()}>
                    {group.headers.map((column) => {
                      const tableColumn = column as TableColumn<D>;

                      return (
                        <SC.TableCell
                          {...column.getFooterProps()}
                          align={tableColumn.align}
                        >
                          {column.render("Footer")}
                        </SC.TableCell>
                      );
                    })}
                  </SC.TableRow>
                ))}
              </SC.TableFooter>
            </SC.Table>
          </SC.Container>
        </SC.TableContainer>
      </SC.RightPaneBox>

      <TransferList
        columns={allColumns}
        visibleColumns={visibleColumns}
        open={modal}
        onClose={() => setModal(!modal)}
        onSortColumns={(orderIds: string[], hiddenIds: string[]) => {
          setColumnOrder(orderIds);
          setHiddenColumns(hiddenIds);
          setModal(!modal);
        }}
      />
    </SC.Container>
  );
};

export default Table;
