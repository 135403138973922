/* eslint-disable prettier/prettier */
import React from "react";

import {
  Typography,
  Box,
  Snackbar,
  Grid,
  Card,
  CardContent,
  Button,
  IconButton,
} from "@material-ui/core";
import clsx from "clsx";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import { Alert, AlertProps } from "components/alert/alert.component";

import {
  useAppErrorHandler,
  isServerError,
  UIError,
  UIErrorCodes,
} from "errors/app.errors";

import ProgressIndicator from "components/progress-indicator/progress-indicator.component";

import AppPermissionValidator, {
  APP_PERMISSION,
} from "components/app-permission-validator/app-permission-validator.component";

import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import PaymentCard from "assets/icons/payment_card.png";

import {
  PaymentMethod,
  useGetCurrentUserOrganisationQuery,
  useCreatePaymentMethodMutation,
  PaymentMethodFragmentFragmentDoc,
  useEditPaymentMethodMutation,
  useDeletePaymentMethodMutation,
} from "graphql/types-and-hooks";

import { PaymentMethodModel } from "pages/payment-management/models/payment-method";
import { createEmptyPaymentMethod } from "graphql/audionaut.utils";
import { isMobileResolution } from "commons/utils/device-info.util";

import PaymentMethodForm from "pages/payment-management/views/components/payment_method/payment_method_form";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import SC from "pages/payment-management/payment-management.styles";

import CarouselGeneric from "components/carousel-generic/carousel-generic.component";
import GenericEmptyMessages from "components/generic-empty-messages/generic-empty-messages-component";
import PaymentMethodItem from "../components/payment_method/payment_method_item";

const { REACT_APP_STRIPE_SECRET_KEY_CORPORATUS } = process.env;

const PaymentMethodScreen: React.FC = () => {
  const classes = SC.paymentMethodStyles();

  const history = useHistory();

  const stripePromise = React.useMemo(
    () =>
      REACT_APP_STRIPE_SECRET_KEY_CORPORATUS
        ? loadStripe(REACT_APP_STRIPE_SECRET_KEY_CORPORATUS)
        : null,
    []
  );

  const { search } = useLocation();
  const currentParam = React.useMemo(
    () => new URLSearchParams(search),
    [search]
  );

  const [redirect, setRedirect] = React.useState<boolean>(false);
  const [snackBarMessage, setSnackBarMessage] = React.useState<AlertProps>();
  const [showEdit, setShowEdit] = React.useState<boolean>(false);
  const [paymentMethodToEdit, setPaymentMethodToEdit] =
    React.useState<PaymentMethodModel>(createEmptyPaymentMethod());

  const handleCloseSnack = React.useCallback(
    (event?: React.SyntheticEvent, reason?: string) => {
      if (reason === "clickaway") {
        return;
      }
      setSnackBarMessage(undefined);
    },
    []
  );

  const {
    data: currentUserOrganisation,
    loading: currentUserOrganisationLoading,
    error: currentUserOrganisationError,
    refetch: currentUserOrganisationRefetch,
  } = useGetCurrentUserOrganisationQuery({
    fetchPolicy: "no-cache",
  });

  const paymentMethods = React.useMemo(
    () =>
      currentUserOrganisation?.getCurrentUserOrganisation.paymentMethods ?? [],
    [currentUserOrganisation]
  );

  const [
    createPaymentMethod,
    { loading: createPaymentMethodLoading, error: createPaymentMethodError },
  ] = useCreatePaymentMethodMutation({
    update(cache, { data }) {
      const newPaymentMethod = data?.createPaymentMethod;
      if (newPaymentMethod) {
        cache.modify({
          fields: {
            GetPaymentMethod(existingPaymentMethods = []) {
              const newPaymentMethodRef = cache.writeFragment({
                id: cache.identify(newPaymentMethod),
                data: newPaymentMethod,
                fragment: PaymentMethodFragmentFragmentDoc,
              });
              return [...existingPaymentMethods, newPaymentMethodRef];
            },
          },
        });
      }
    },
  });

  const [
    editPaymentMethod,
    { loading: editPaymentMethodLoading, error: editPaymentMethodError },
  ] = useEditPaymentMethodMutation();

  const [deletePaymentMethod, { loading: deletePaymentMethodLoading }] =
    useDeletePaymentMethodMutation();

  const loading =
    currentUserOrganisationLoading ||
    createPaymentMethodLoading ||
    editPaymentMethodLoading ||
    deletePaymentMethodLoading;
  const errorHandler = useAppErrorHandler(
    currentUserOrganisationError ||
      createPaymentMethodError ||
      editPaymentMethodError
  );

  const onNewPaymentMethodAdded = React.useCallback(
    async (paymentMethodData: PaymentMethodModel) => {
      try {
        await createPaymentMethod({
          variables: {
            isDefault: paymentMethodData?.isDefault ?? false,
            stripePaymentMethodId: paymentMethodData.stripePaymentMethodId,
            holderName: paymentMethodData.holderName,
          },
        });

        setSnackBarMessage({
          message: "New payment method has been added successfully",
          severity: "success",
        });

        currentUserOrganisationRefetch();
        setShowEdit(false);

        const redirectTo = currentParam.get("redirectTo");
        if (redirectTo) {
          setTimeout(() => {
            // setSnackBarMessage(undefined);
            setSnackBarMessage({
              message: "Redirecting to plans page!",
              severity: "warning",
            });
          }, 1000);
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
          // setInitialEditable(true);
          // setInitialSaveActive(true);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while creating the payment method"
            )
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createPaymentMethod, errorHandler, currentParam]
  );

  const handleUpdatePaymentMethodItem = React.useCallback(
    async (pm: PaymentMethod | undefined) => {
      try {
        await editPaymentMethod({
          variables: {
            id: pm!.id!,
            isDefault: !(pm?.isDefault ?? false),
          },
        });
        currentUserOrganisationRefetch();
        setShowEdit(false);

        setSnackBarMessage({
          message: "Payment method has been updated successfully!",
          security: "success",
        });

        const redirectTo = currentParam.get("redirectTo");
        if (redirectTo) {
          setTimeout(() => {
            // setSnackBarMessage(undefined);
            setSnackBarMessage({
              message: "Redirecting to plans page!",
              severity: "warning",
            });
          }, 1000);
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        }
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
          // setInitialEditable(true);
          // setInitialSaveActive(true);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while updating the payment method"
            )
          );
        }
      }
    },
    [
      editPaymentMethod,
      errorHandler,
      currentUserOrganisationRefetch,
      currentParam,
    ]
  );

  const handleDeletePaymentMethod = React.useCallback(
    async (pm: PaymentMethod | undefined) => {
      try {
        await deletePaymentMethod({
          variables: {
            id: pm!.id!,
          },
        });
        currentUserOrganisationRefetch();
        setSnackBarMessage({
          message: "Payment method deleted successfully",
          severity: "warning",
        });
      } catch (error: any) {
        if (isServerError(error)) {
          errorHandler(error);
          // setInitialEditable(true);
          // setInitialSaveActive(true);
        } else {
          errorHandler(
            new UIError(
              UIErrorCodes.COULD_NOT_REALIZE_THE_OPERATION,
              "An error has ocurred while deleting the payment method"
            )
          );
        }
      }
    },
    [deletePaymentMethod, errorHandler, currentUserOrganisationRefetch]
  );

  React.useEffect(() => {
    if (paymentMethods) {
      const methodsExists = paymentMethods ? paymentMethods.length === 0 : true;
      setPaymentMethodToEdit((oldPaymentMethodToEdit) => ({
        ...oldPaymentMethodToEdit,
        isDefault: methodsExists,
      }));
    }
  }, [paymentMethods]);

  return (
    <SC.PMContainer>
      <ProgressIndicator open={loading} />
      <SC.PMContent>
        <Snackbar
          open={!!snackBarMessage}
          autoHideDuration={3000}
          onClose={handleCloseSnack}
        >
          <Alert
            onClose={handleCloseSnack}
            severity={snackBarMessage?.severity}
            message={snackBarMessage?.message}
          />
        </Snackbar>
        <SC.PMHeader component="div">
          <Box component="div" display="flex" flex="1">
            <Typography
              hidden={showEdit && isMobileResolution()}
              variant="h1"
              color="primary"
            >
              <IconButton
                hidden={!isMobileResolution()}
                aria-label="back"
                size="small"
                onClick={() => {
                  history.goBack();
                }}
              >
                <ArrowBackIosIcon color="primary" />
              </IconButton>
              Payment Information
            </Typography>
            <Typography
              hidden={!showEdit || !isMobileResolution()}
              variant="h1"
              color="primary"
            >
              <IconButton
                aria-label="back"
                size="small"
                onClick={() => setShowEdit(false)}
              >
                <ArrowBackIosIcon color="primary" />
              </IconButton>
              {paymentMethodToEdit.id ? "Edit " : "New "}
              Payment Information
            </Typography>
          </Box>
        </SC.PMHeader>
        <SC.SBBody
          className={classes.sectionContainer}
          component="div"
          key="groups-table"
        >
          {!showEdit ? (
            <AppPermissionValidator
              appPermission={
                APP_PERMISSION.SUBSCRIPTION_MANAGEMENT_ADDEDIT_PAYMENT_METHODS
              }
            >
              <Box hidden={isMobileResolution()}>
                <Typography
                  className={classes.formTitle}
                  style={{ marginBottom: "30px" }}
                  color="primary"
                >
                  Add Payment Method
                </Typography>
              </Box>
              <Card
                className={clsx(
                  classes.itemContainer,
                  classes.addPaymentContainer
                )}
                onClick={() => setShowEdit(!showEdit)}
              >
                <CardContent className={classes.addPaymentMethodItem}>
                  <AddIcon color="primary" />
                  <Typography color="primary">Add Payment Method</Typography>
                </CardContent>
              </Card>
            </AppPermissionValidator>
          ) : null}
          {showEdit ? (
            // <SC.PMBody component="div" key="groups-table">
            //   <Box>
            //     <Typography className={classes.sectionTitle}>
            //       Select Payment Method
            //     </Typography>
            //     <Button className={classes.debitCreditBtn}>
            //       Debit/Credit card
            //     </Button>
            <Elements stripe={stripePromise}>
              <PaymentMethodForm
                paymentMethodData={paymentMethodToEdit}
                onSubmit={onNewPaymentMethodAdded}
                onCancel={() => setShowEdit(false)}
              />
            </Elements>
          ) : (
            //  </Box>
            // </SC.PMBody>
            <Box>
              <Box className={classes.headerPlan}>
                <Box className={classes.headerImage}>
                  <img src={PaymentCard} alt="membership-icon" />
                </Box>
                <Typography className={classes.sectionTitle} color="primary">
                  Payment Method
                </Typography>
              </Box>
              <Box
                hidden={!isMobileResolution() || paymentMethods.length === 0}
              >
                <CarouselGeneric
                  type="plans"
                  items={
                    paymentMethods.map((paymentMethod: any) => (
                      <PaymentMethodItem
                        paymentMethod={paymentMethod}
                        setAsDefault={handleUpdatePaymentMethodItem}
                        deletItem={handleDeletePaymentMethod}
                      />
                    )) as any[]
                  }
                  hideDots={paymentMethods.length === 1}
                  customPaddingLeft={paymentMethods.length > 1 ? 50 : 0}
                  customPaddingRight={paymentMethods.length > 1 ? 50 : 0}
                  startIndex={
                    paymentMethods.findIndex(
                      (paymentMethos) => paymentMethos?.isDefault
                    ) + 1
                  }
                />
              </Box>
              <Box hidden={isMobileResolution() || paymentMethods.length === 0}>
                <Grid container spacing={3} xl={12} lg={12} md={12} xs={12}>
                  {paymentMethods.map((paymentMethod: any) => (
                    <Grid
                      key={`${paymentMethod.id}-item`}
                      item
                      xs={12}
                      md={12}
                      lg={6}
                      xl={4}
                      className={classes.gridPaymentItem}
                    >
                      <PaymentMethodItem
                        paymentMethod={paymentMethod}
                        setAsDefault={handleUpdatePaymentMethodItem}
                        deletItem={handleDeletePaymentMethod}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Box>

              <Box hidden={paymentMethods.length > 0}>
                <SC.NoResults
                  show
                  title="No payment methods"
                  description="We don't have any payment methos registered."
                />
              </Box>
            </Box>
          )}
        </SC.SBBody>
      </SC.PMContent>
      {redirect ? <Redirect to={currentParam.get("redirectTo")!} /> : null}
    </SC.PMContainer>
  );
};

export default PaymentMethodScreen;
